import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AdminContext } from '../../../../Context/AdminContext'
import AdminLayout from '../../../Layouts/AdminLayout'


function ListVoucher({ history }) {

    const adminContext = useContext(AdminContext)

    const {
        setAccountID,
        setActive,
        setSelectedVoucher
    } = adminContext

    useEffect(() => {
        window.feather.replace()
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [vouchers, setVouchers] = useState([])
    const [isActiveModal, setIsActiveModal] = useState(false)
    const [voucherForm, setVoucherForm] = useState({
        name: '',
        code: ''
    })

    useEffect(() => {
        window.feather.replace()
    }, [isActiveModal])

    const fetchVouchers = () => {
        Axios.get(BASE_URL + "/voucher", {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(response => {
            if (response.data.status) {
                setVouchers(response.data.data.list)
            }
        })
    }

    const addVoucher = () => {
        setIsSubmitting(true)

        const formData = new FormData()
        formData.append("name", voucherForm.name)
        formData.append("code", voucherForm.code)

        Axios.post(BASE_URL + "/voucher", formData, {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(response => {
            if (response.data.status) {
                fetchVouchers()
                setIsSubmitting(false)
                setIsActiveModal(false)
            } else {
                Object.keys(response.data.errors).map((value) => {
                    toast.error(response.data.errors[value][0])
                })
                setIsSubmitting(false)
            }
        }).catch(error => {
            Object.keys(error.response.data.errors).map((value) => {
                toast.error(error.response.data.errors[value][0])
            })
            setIsSubmitting(false)
        })
    }

    useEffect(() => {
        fetchVouchers()
    }, [])

    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <h4>Daftar Voucher</h4>
                </div>
                <div className={"col-md-6"}>
                    <button class="btn btn-pills btn-warning mt-2 mr-2" style={{ float: 'right' }} onClick={() => { setIsActiveModal(!isActiveModal) }}>Tambah Voucher</button>
                </div>
            </div>
            <br />
            <hr />
            <div class="table-responsive bg-white shadow rounded">
                <table class="table mb-0 table-center">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nama</th>
                            <th scope="col">Code</th>
                            <th scope="col">Total Penggunaan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vouchers.map((voucher, index) =>
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <th scope="col"><a href={"#"} onClick={() => {setSelectedVoucher(voucher); setActive(4)}}>{voucher.name}</a></th>
                                    <th scope="col">{voucher.code}</th>
                                    <th scope="col">{voucher.used}</th>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {
                isActiveModal ?
                    <div class="modal fade show" id="LoginForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: 'block', paddingRight: 17, backgroundColor: 'rgba(0,0,0, 0.5)' }} aria-modal="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content rounded shadow border-0">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalCenterTitle">Tambah Voucher!</h5>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group position-relative">
                                                <label>Nama Voucher </label>
                                                <i data-feather="user" className="fea icon-sm icons"></i>
                                                <input type="text" className="form-control pl-5" placeholder="Nama Voucher" value={voucherForm.name} onChange={(event) => { setVoucherForm({...voucherForm, name: event.target.value}) }} required="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group position-relative">
                                                <label>Kode Voucher </label>
                                                <i data-feather="mail" className="fea icon-sm icons"></i>
                                                <input type="text" className="form-control pl-5" placeholder="Kode Voucher"value={voucherForm.code} onChange={(event) => { setVoucherForm({...voucherForm, code: event.target.value}) }} required="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" disabled={isSubmitting} onClick={() => {addVoucher()}} class="btn btn-primary">Tambah</button>
                                    <button type="button" class="btn btn-success" onClick={() => { setIsActiveModal(false) }}>Kembali</button>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </Fragment>
    )
}

export default ListVoucher