import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ACCOUNT_KEY, TOKEN_KEY } from '../../Constants/constant'
import { AdminContext } from '../../Context/AdminContext'
import Dashboard from '../Pages/Admin/Dashboard/dashboard'
import ListAccount from '../Pages/Admin/ListAccount'
import DetailAccount from '../Pages/Admin/ListAccount/DetailAccount'
import DetailVoucher from '../Pages/Admin/Voucher/DetailVoucher'
import ListVoucher from '../Pages/Admin/Voucher/index'

import Background from './../../Assets/Images/account/bg.png'

function AdminLayout({ children }) {

    const adminContext = useContext(AdminContext)


    const {
        active,
        setActive,
        fetchAdmin,
        admin,
        setAdmin,
    } = adminContext
    const [isRender, setIsRender] = useState(false)


    useEffect(() => {
        if (!localStorage.getItem(TOKEN_KEY) || !localStorage.getItem(ACCOUNT_KEY)) {
            window.location.href = "/login"
        }

        fetchAdmin()

        window.feather.replace()
    }, [])

    useEffect(() => {
        if (admin?.type == 3) {
            setIsRender(true)
        }
    }, [admin])

    const logOut = () => {
        localStorage.clear()
        setAdmin(null)
        window.location.href = "/login"
    }

    const content = () => {
        switch (active) {
            case 0:
                return <Dashboard />
            case 1:
                return <ListAccount />
            case 2:
                return <DetailAccount />
            case 3:
                return <ListVoucher />
            case 4:
                return <DetailVoucher />
        }
    }

    return (
        <Fragment>
            {
                isRender ?
                    <div>
                        {/* Profile Head */}
                        <section className="bg-profile d-table w-100 bg-primary" style={{ background: `url(${Background}) center center` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card public-profile border-0 rounded shadow" style={{ zIndex: 1 }}>
                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="row align-items-end">
                                                            <div className="col-md-12 text-md-left text-center mt-4 mt-sm-0">
                                                                <h3 className="title mb-0">Rahmat Dwi Jatmiko</h3>
                                                                <small className="text-muted h6 mr-2">Selamat datang admin SMK Telkom Sidoarjo</small>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section mt-60">
                            <div className="container mt-lg-3">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="sidebar sticky-bar p-4 rounded shadow">

                                            <div className="widget">
                                                <div className="row">
                                                    <div className="col-6 mt-4 pt-2">
                                                        <a onClick={() => { setActive(0) }} className={"accounts rounded d-block shadow text-center py-3" + (active == 0 ? " active" : "")}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-dashboard"></i></span>
                                                            <h6 className="title text-dark h6 my-0">Dashboard</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a onClick={() => { setActive(1) }} className={"accounts rounded d-block shadow text-center py-3" + (active == 1 ? " active" : "")}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-transaction"></i></span>
                                                            <h6 className="title text-dark h6 my-0">Pendaftar</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a onClick={() => { setActive(3) }} className={"accounts rounded d-block shadow text-center py-3" + (active == 3 ? " active" : "")}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-bill"></i></span>
                                                            <h6 className="title text-dark h6 my-0">Voucher</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" className="accounts rounded d-block shadow text-center py-3" onClick={() => {
                                                            logOut()
                                                        }}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-sign-out-alt"></i></span>
                                                            <h6 className="title text-dark h6 my-0">Logout</h6>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-9 col-12">
                                        {children}
                                        {content()}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div> : null
            }
        </Fragment>
    )
}

export default AdminLayout;