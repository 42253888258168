import React, { Fragment, useContext, useEffect, useState } from 'react'

import Background from './../../Assets/Images/account/bg.png'

import { ACCOUNT_KEY, BASE_URL, TOKEN_KEY } from '../../Constants/constant';
import Axios from 'axios';
import Payment from '../Pages/Student/Payment';
import Registration from '../Pages/Student/Form';
import { AppContext } from '../../Context/AppContext';
import DocumentUpload from '../Pages/Student/File';
import Approval from '../Pages/Student/File/approval';
import ReRegistrantion from '../Pages/Student/Reregistration';
import Home from '../Pages/Student/Home/Index';

function AppLayout({ children, history }) {
    const appContext = useContext(AppContext)

    const {
        fetchAccount,
        account,
        active,
        setAccount,
        finishSection,
    } = appContext
    const [isRender, setIsRender] = useState(false)


    useEffect(() => {
        if (!localStorage.getItem(TOKEN_KEY) || !localStorage.getItem(ACCOUNT_KEY)) {
            window.location.href = "/login"
        }

        fetchAccount()

        window.feather.replace()
    }, [])

    useEffect(() => {
        if(account.type == 1) {
            setIsRender(true)
        }
    }, [account])

    const logOut = () => {
        localStorage.clear()
        setAccount(null)
        window.location.href = "/login"
    }

    const renderContent = () => {
        switch (active) {
            case 0:
                return <Home/>
            case 1:
                return <Payment />
            case 2:
                return <Registration />
            case 3:
                return <DocumentUpload />
            case 4:
                return <Approval />
            case 5:
                return <ReRegistrantion />
        }
    }

    return (
        <Fragment>
            {
                isRender ?
                    <div>
                        {/* Profile Head */}
                        <section className="bg-profile d-table w-100 bg-primary" style={{ background: `url(${Background}) center center`, backgroundSize: 'cover' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card public-profile border-0 rounded shadow" style={{ zIndex: 1 }}>
                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="row align-items-end">
                                                            <div className="col-md-12 text-md-left text-center mt-4 mt-sm-0">
                                                                <h3 className="title mb-0">{account?.name}</h3>
                                                                <small className="text-muted h6 mr-2">
                                                                    {
                                                                        active == 1 ?
                                                                        "Selanjutnya, silakan kamu melakukan pembayaran biaya pendaftaran sesuai dengan informasi berikut." : 
                                                                        (
                                                                            active == 2 ?
                                                                            "Terima kasih telah melakukan biaya pendaftaran. Selanjutanya, lengkapi data profil kamu sesuai dengan form yang disediakan dengan benar" :
                                                                            (
                                                                                active == 3 ?
                                                                                "Untuk melengkapi formulir pendaftaran yang telah kamu isi. Silakan mengunggah berkas pendaftaran berikut" :
                                                                                "Selamat datang di SMK Telkom Sidorjo"
                                                                            )
                                                                        )
                                                                    }
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section mt-60">
                            <div className="container mt-lg-3">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="sidebar sticky-bar p-4 rounded shadow">

                                            <div className="widget">
                                                <div className="row">
                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" className={"accounts rounded d-block shadow text-center py-3" + (active == 1 ? " active" : "")} style={finishSection[1] ? {
                                                            backgroundColor: '#4caf50',
                                                            color: "white"
                                                        } : {}}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-transaction" style={finishSection[1] ? {
                                                                color: "white"
                                                            } : {}}></i></span>
                                                            <h6 className={"title h6 my-0 text-dark"}>Pembayaran</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" className={"accounts rounded d-block shadow text-center py-3" + (active == 2 ? " active" : "")} style={finishSection[2] ? {
                                                            backgroundColor: '#4caf50',
                                                            color: "white"
                                                        } : {}}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-users-alt" style={finishSection[2] ? {
                                                            color: "white"
                                                        } : {}}></i></span>
                                                            <h6 className="title text-dark h6 my-0">Formulir</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" className={"accounts rounded d-block shadow text-center py-3" + (active == 3 ? " active" : "")} style={finishSection[3] ? {
                                                            backgroundColor: '#4caf50',
                                                            color: "white"
                                                        } : {}}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-file"  style={finishSection[3] ? {
                                                            color: "white"
                                                        } : {}}></i></span>
                                                            <h6 className="title text-dark h6 my-0">Upload Berkas</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" className={"accounts rounded d-block shadow text-center py-3" + (active == 4 ? " active" : "")} style={finishSection[4] ? {
                                                            backgroundColor: '#4caf50',
                                                            color: "white"
                                                        } : {}}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-check" style={finishSection[4] ? {
                                                            color: "white"
                                                        } : {}}></i></span>
                                                            <h6 className="title text-dark h6 my-0">Konfirmasi</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" className={"accounts rounded d-block shadow text-center py-3" + (active == 5 ? " active" : "")} style={finishSection[5] ? {
                                                            backgroundColor: '#4caf50',
                                                            color: "white"
                                                        } : {}}>
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-envelope-star" style={finishSection[5] ? {
                                                            color: "white"
                                                        } : {}}></i></span>
                                                            <h6 className="title text-dark h6 my-0">Daftar Ulang</h6>
                                                        </a>
                                                    </div>

                                                    <div className="col-6 mt-4 pt-2">
                                                        <a href="#" onClick={logOut} className="accounts rounded d-block shadow text-center py-3">
                                                            <span className="pro-icons h3 text-muted"><i className="uil uil-sign-out-alt"></i></span>
                                                            <h6 className="title text-dark h6 my-0">Logout</h6>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-8 col-12">
                                        {children}
                                        {renderContent()}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div> : null
            }
        </Fragment>
    )
}

export default AppLayout;