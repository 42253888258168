import React, { Fragment, useContext, useEffect, useState } from 'react'

import Axios from 'axios'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { toast } from 'react-toastify'
import { AppContext } from '../../../../Context/AppContext'

function DocumentUpload({ history }) {

    const appContext = useContext(AppContext)

    const {
        setActive,
        account,
        fetchAccount,
    } = appContext

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form, setForm] = useState({
        photo: null,
        birthCertificate: null,
        familyCard: null,
        fatherIdentityCard: null,
        motherIdentityCard: null,
        healthDocument: null,
        colorBlindDocument: null,
        schoolCertificate: null,
        NISN: null,
    })

    const [report, setReport] = useState({
        semester1: [],
        semester2: [],
        semester3: [],
        semester4: [],
    })

    const [score, setScore] = useState({
        math1: "",
        math2: "",
        math3: "",
        math4: "",
        indonesia1: "",
        indonesia2: "",
        indonesia3: "",
        indonesia4: "",
        english1: "",
        english2: "",
        english3: "",
        english4: "",
        religion1: "",
        religion2: "",
        religion3: "",
        religion4: "",
        nationality1: "",
        nationality2: "",
        nationality3: "",
        nationality4: "",
    })

    useEffect(() => {
        window.feather.replace()
    }, [])

    const onSubmitBasic = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/document", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan berkas dasar")

                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    const onSubmitReport = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        const semester1 = report.semester1;

        for (let i = 0; i < semester1.length; i++) {
            formData.append(`semester1[${i}]`, semester1[i])
        }

        const semester2 = report.semester2;

        for (let i = 0; i < semester2.length; i++) {
            formData.append(`semester2[${i}]`, semester1[i])
        }

        const semester3 = report.semester3;

        for (let i = 0; i < semester1.length; i++) {
            formData.append(`semester3[${i}]`, semester1[i])
        }

        const semester4 = report.semester4;

        for (let i = 0; i < semester4.length; i++) {
            formData.append(`semester4[${i}]`, semester4[i])
        }

        Axios.post(BASE_URL + "/report", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan berkas raport")

                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    const onSubmitScore = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(score)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/score", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan nilai raport")

                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    return (
        <Fragment>
            <div>
                {
                    !(account.uncompletedSteps?.registrantDocument) ?
                        <div>
                            <h4>Upload Berkas</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Unggah Pas Foto</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, photo: event.target.files[0] }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Scan Akta Kelahiran</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, birthCertificate: event.target.files[0] }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Scan Kartu Keluarga</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, familyCard: event.target.files[0] }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NISN</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, NISN: event.target.files[0] }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Surat Keterangan Sehat</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, healthDocument: event.target.files[0] }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Surat Keterangan Tidak Buta Warna</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, colorBlindDocument: event.target.files[0] }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Scan Keterangan Lulus / Fotokopi Ijazah</label>
                                        <input type="file" className="form-control" onChange={(event) => { setForm({ ...form, schoolCertificate: event.target.files[0] }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={onSubmitBasic}>Upload Berkas Dasar</button>
                            </div>
                        </div> : null
                }

                {
                    !(account.uncompletedSteps?.reportDocument) ?
                        <div>
                            <br />
                            <h4>Upload Raport</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Rapor Semester 1</label>
                                        <input type="file" multiple={true} className="form-control" onChange={(event) => { setReport({ ...report, semester1: event.target.files }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Rapor Semester 2</label>
                                        <input type="file" multiple={true} className="form-control" onChange={(event) => { setReport({ ...report, semester2: event.target.files }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Rapor Semester 3</label>
                                        <input type="file" multiple={true} className="form-control" onChange={(event) => { setReport({ ...report, semester3: event.target.files }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Rapor Semester 4</label>
                                        <input type="file" multiple={true} className="form-control" onChange={(event) => { setReport({ ...report, semester4: event.target.files }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={onSubmitReport}>Upload Berkas Raport</button>
                            </div>
                        </div> : null
                }

                {
                    !(account.uncompletedSteps?.reportScore) ?
                        <div>
                            <br />
                            <h4>Nilai Raport</h4>
                            <p>Untuk mempermudah proses seleksi berkas rapor, silakan kamu mengisi nilai pengetahuan mata pelajaran berikut</p>
                            <table class="table mb-0 table-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Mata Pelajaran</th>
                                        <th scope="col">Semester 1</th>
                                        <th scope="col">Semester 2</th>
                                        <th scope="col">Semester 3</th>
                                        <th scope="col">Semester 4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Matemarika</th>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, math1: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, math2: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, math3: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, math4: event.target.value }) }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Bahasa Indonesia</th>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, indonesia1: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, indonesia2: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, indonesia3: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, indonesia4: event.target.value }) }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Basha Inggris</th>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, english1: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, english2: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, english3: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, english4: event.target.value }) }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Agama</th>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, religion1: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, religion2: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, religion3: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, religion4: event.target.value }) }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">PKN</th>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, nationality1: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, nationality2: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, nationality3: event.target.value }) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-group position-relative">
                                                <input type="text" className="form-control" onChange={(event) => { setScore({ ...score, nationality4: event.target.value }) }} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="col-md-12">
                                <p>Dengan menekan tombol simpan, data yang kamu isikan di atas adalah benar dan dapat dipertanggungjawabkan</p>
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={onSubmitScore}>Upload Nilai Raport</button>
                            </div>
                        </div> : null
                }
            </div>
        </Fragment>
    )
}

export default DocumentUpload