import React, { Fragment, useContext, useEffect, useState } from 'react'

import Axios from 'axios'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { toast } from 'react-toastify'
import { AppContext } from '../../../../Context/AppContext'

function Registration({ history }) {

    const appContext = useContext(AppContext)

    const {
        setActive,
        fetchAccount,
    } = appContext

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form, setForm] = useState({
        accountID: "",
        type: "1",
        gender: "1",
        bornDate: "",
        parentName: "",
        address: "",
        graduateYear: 2019,
        schoolName: "",
        schoolDistrict: "",
        schoolCity: "",
        schoolProvince: "",
        originInformation: "Orang tua peserta didik lain",
        reason: "",
    })
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])

    const fetchProvince = () => {
        Axios.get(BASE_URL + "/location/province")
            .then(response => {

                if (response.data.status) {
                    setProvinces(response.data.data.list)
                }
            })
    }

    const fetchCities = (provinceID) => {
        Axios.get(BASE_URL + "/location/city/" + provinceID)
            .then(response => {

                if (response.data.status) {
                    setCities(response.data.data.list)
                }
            })
    }

    const fetchDistricts = (cityID) => {
        Axios.get(BASE_URL + "/location/district/" + cityID)
            .then(response => {

                if (response.data.status) {
                    setDistricts(response.data.data.list)
                }
            })
    }

    useEffect(() => {
        window.feather.replace()
        fetchProvince()
    }, [])

    useEffect(() => {
        if (form.schoolProvince) {
            fetchCities(form.schoolProvince)
        }
    }, [form.schoolProvince])

    useEffect(() => {
        if (form.schoolCity) {
            fetchDistricts(form.schoolCity)
        }
    }, [form.schoolCity])

    const onSubmit = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/registration", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan informasi")
                    fetchAccount();

                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    return (
        <Fragment>
            <div>
                <h4>Info Dasar</h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Pendaftaran Jalur</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, type: event.target.value }) }}>
                                <option value="1">Gelombang 1</option>
                                {/* <option value="2">Ujian</option> */}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Jenis Kelamin</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, gender: event.target.value }) }}>
                                <option value="1">Laki Laki</option>
                                <option value="2">Perempuan</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Tanggal Lahir</label>
                            <input type="date" className="form-control" onChange={(event) => { setForm({ ...form, bornDate: event.target.value }) }} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Nama Orang Tua</label>
                            <input type="text" className="form-control" placeholder={"Nama Orang Tua"} onChange={(event) => { setForm({ ...form, parentName: event.target.value }) }} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Alamat</label>
                            <textarea type="text" className="form-control" onChange={(event) => { setForm({ ...form, address: event.target.value }) }} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Tahun Lulus</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, graduateYear: event.target.value }) }}>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr />
                <h4>Info Sekolah</h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Nama Sekolah</label>
                            <input type="text" className="form-control" placeholder={"Nama Sekolah"} onChange={(event) => { setForm({ ...form, schoolName: event.target.value }) }} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Provinsi Sekolah</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, schoolProvince: event.target.value }) }}>
                                <option value="">Pilih Provinsi</option>
                                {
                                    provinces.map(province =>
                                        <option value={province.id}>{province.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Kota Sekolah</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, schoolCity: event.target.value }) }}>
                                <option value="">Pilih Kota</option>
                                {
                                    cities.map(city =>
                                        <option value={city.id}>{city.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Kecamatan Sekolah</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, schoolDistrict: event.target.value }) }}>
                                <option value="">Pilih Kecamatan</option>
                                {
                                    districts.map(district =>
                                        <option value={district.id}>{district.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <hr />
                <h4>Kuesioner</h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Dari mana mengetahui Informasi SMK Telkom Sidoarjo?</label>
                            <select class="form-control custom-select" onChange={(event) => { setForm({ ...form, originInformation: event.target.value }) }}>
                                <option value="Orang tua peserta didik lain">Orang tua peserta didik lain</option>
                                <option value="Saudara">Saudara</option>
                                <option value="Kakak Tingkat">Kakak Tingkat</option>
                                <option value="Brosur/Poster">Brosur/Poster</option>
                                <option value="Website">Website</option>
                                <option value="Instagram">Instagram</option>
                                <option value="SMS ads">SMS ads</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Tiktok">Tiktok</option>
                                <option value="Berita Online">Berita Online</option>
                                <option value="Lainnya">Lainnya</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group position-relative">
                            <label>Alasan Masuk SMK Telkom Sidoarjo</label>
                            <textarea type="text" className="form-control" onChange={(event) => { setForm({ ...form, reason: event.target.value }) }} />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <p>Dengan menekan tombol simpan, data yang kamu isikan di atas adalah benar dan dapat dipertanggung jawabkan</p>
                    <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={onSubmit}>Simpan</button>
                </div>
            </div>
        </Fragment>
    )
}

export default Registration