import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'


import SignUp from './../../../Assets/Images/user/signup.png';
import { ACCOUNT_KEY, BASE_URL, TOKEN_KEY } from '../../../Constants/constant';
import { toast } from 'react-toastify';

function Login({history}) {

    useEffect(() => {
        window.feather.replace()
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form, setForm] = useState({
        email: "", 
        password: "",
    })
    const [errors, setErrors] = useState({})

    const onSubmit = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value)
        }

        axios.post(BASE_URL + "/login", formData)
            .then(response => {
                
                if(response.data.status) {
                    toast.success("Success login")

                    localStorage.setItem(TOKEN_KEY, response.data.data.token)
                    localStorage.setItem(ACCOUNT_KEY, JSON.stringify(response.data.data.account))

                    if(response.data.data.account.type == 1) {
                        history.push("/profile")
                    } else if(response.data.data.account.type == 3) {
                        history.push("/admin")
                    }
                

                    setIsSubmitting(false)
                    setErrors({})
                } else {
                    setIsSubmitting(false)
                    setErrors(response.data.errors)
                }

            })
            .catch(error => {
                setIsSubmitting(false)
                setErrors(error.response.data.errors)
            })
    }

    return (
        <Fragment>
            <div className="back-to-home rounded d-none d-sm-block">
                <Link to={"/"} className={"btn btn-icon btn-soft-primary"}><i data-feather="home" className="icons"></i></Link>
            </div>

            {/* Register */}
            <section className="bg-home d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6">
                            <div className="mr-lg-5">
                                <img src={SignUp} className="img-fluid d-block mx-auto" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="card login_page shadow rounded border-0">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Masuk</h4>
                                    {
                                            Object.keys(errors).length ?
                                                <div className={"alert alert-danger"}>
                                                    <ul>
                                                        {
                                                            Object.keys(errors).map((value, index) => <li key={index}>{errors[value][0]}</li>)
                                                        }
                                                    </ul>
                                                </div> : null
                                        }
                                    <div className="login-form mt-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Email <span className="text-danger">*</span></label>
                                                    <i data-feather="mail" className="fea icon-sm icons"></i>
                                                    <input type="email" className="form-control pl-5" value={form.email} onChange={event => { setForm({...form, email: event.target.value}) }} placeholder="Email" name="email" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Password <span className="text-danger">*</span></label>
                                                    <i data-feather="phone" className="fea icon-sm icons"></i>
                                                    <input type="password" className="form-control pl-5" value={form.password} onChange={event => { setForm({...form, password: event.target.value}) }} placeholder="Password" name="password" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={onSubmit}>Masuk</button>
                                            </div>
                                            <div className="mx-auto">
                                                    <p className="mb-0 mt-3"><small className="text-dark mr-2">Apakah Anda belum memiliki akun ?</small> <Link to={"/register"} className={"text-dark font-weight-bold"}> Daftar </Link></p>
                                                    <p className="mb-0 mt-3"><small className="text-dark mr-2">Apakah Anda lupa password ?</small> <Link to={"/forget-password"} className={"text-dark font-weight-bold"}> Lupa Password </Link></p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Login