import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AdminContext } from '../../../../Context/AdminContext'
import AdminLayout from '../../../Layouts/AdminLayout'
import queryString from 'query-string'


function ListAccount({ history }) {

    const adminContext = useContext(AdminContext)

    const {
        setAccountID,
        setActive
    } = adminContext

    useEffect(() => {
        window.feather.replace()
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [page, setPage] = useState({
        isMore: false,
        isBefore: false,
    })
    const [parameter, setParameter] = useState({
        keyword: '',
        currentPage: 1,
        pageSize: 10,
        sort: 'name',
        order: 'asc'
    })

    const fetchAccount = () => {
        setIsSubmitting(true)
        Axios.get(BASE_URL + "/account?" + queryString.stringify(parameter), {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(response => {
            if (response.data.status) {
                setPage({ isMore: response.data.data.pagination.isMore, isBefore: response.data.data.pagination.currentPage > 1 })
                setAccounts(response.data.data.list)
            }

            setIsSubmitting(false)
        }).catch(() => {
            setIsSubmitting(false)
        })
    }

    useEffect(() => {

    }, [])

    useEffect(() => {
        fetchAccount()
    }, [parameter.currentPage])

    const detailAccount = (accountID) => {
        setAccountID(accountID)
        setActive(2)
    }

    const renderStatus = (account, type, label) => {
        if (account.uncompletedSteps[type]) {
            return <button class="btn btn-pills btn-success mt-2 mr-2">{label}</button>
        } else {
            return <button class="btn btn-pills btn-warning mt-2 mr-2">{label}</button>
        }
    }

    return (
        <Fragment>
            <h4>Daftar Pendaftar</h4>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group position-relative">
                        <input type="text" className="form-control" placeholder={"Keyword"} onChange={(event) => { setParameter({ ...parameter, keyword: event.target.value }) }} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group position-relative">
                        <select class="form-control custom-select" onChange={(event) => { setParameter({ ...parameter, sort: event.target.value }) }}>
                            <option value="name">Nama</option>
                            <option value="created">Tanggal Registrasi</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group position-relative">
                        <select class="form-control custom-select" onChange={(event) => { setParameter({ ...parameter, order: event.target.value }) }}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group position-relative">
                        <button className="btn btn-primary" disabled={isSubmitting} onClick={fetchAccount}>Cari</button>
                    </div>
                </div>
            </div>
            <div class="table-responsive bg-white shadow rounded">
                <table class="table mb-0 table-center">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nama</th>
                            <th scope="col">Email</th>
                            <th scope="col">Nomor Telepon</th>
                            <th scope="col">Tanggal Registrasi</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            accounts.map((account, index) =>
                                <tr key={index}>
                                    <th scope="row">{(parameter.pageSize * (parameter.currentPage - 1)) + index + 1}</th>
                                    <th scope="col" onClick={() => { detailAccount(account.ID) }}><a href={"#"}>{account.name}</a></th>
                                    <th scope="col">{account.email}</th>
                                    <th scope="col">{account.phone}</th>
                                    <th scope="col">{account.created}</th>
                                    <th scope="col" style={{ minWidth: 400 }}>
                                        {renderStatus(account, "confirmed", "Pembayaran")}
                                        {renderStatus(account, "form", "Formulir")}
                                        <button className={"btn btn-pills mt-2 mr-2" + (account.registrant?.type == 1 ?
                                            (
                                                account.uncompletedSteps.registrantDocument && account.uncompletedSteps.reportScore && account.uncompletedSteps.reportDocument ? " btn-success" : " btn-warning"
                                            ) : (account.uncompletedSteps.registrantDocument ? " btn-success" : " btn-warning"))}>Upload Berkas</button>
                                        {renderStatus(account, "documentConfirmed", "Konfirmasi Dokumen")}
                                        {renderStatus(account, "reregistration", "Daftar Ulang")}
                                        {renderStatus(account, "registrationConfirmed", "Konfirmasi Daftar Ulang")}
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <br />
            <div className="row">
                <div className={"col-md-12"} style={{ justifyContent: 'center' }}>
                    <ul class="pagination" style={{ justifyContent: 'center' }}>
                        {
                            page.isBefore ? <li disabled={isSubmitting} class="page-item" onClick={() => { setParameter({ ...parameter, currentPage: parameter.currentPage - 1 }) }}><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li> : null
                        }
                        {
                            page.isMore ? <li disabled={isSubmitting} class="page-item" onClick={() => { setParameter({ ...parameter, currentPage: parameter.currentPage + 1 }) }}><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li> : null
                        }
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default ListAccount