import React, { Fragment, useEffect, useState } from 'react'
import AppLayout from '../../../Layouts/AppLayout'


function Profile({ history }) {

    useEffect(() => {
        window.feather.replace()
    }, [])

    return (
        <Fragment>
            <AppLayout>
                <div></div>
            </AppLayout>
        </Fragment>
    )
}

export default Profile