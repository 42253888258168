import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'

import SignUp from './../../../Assets/Images/user/signup.png';
import { BASE_URL } from '../../../Constants/constant';
import ReCAPTCHA from "react-google-recaptcha";

class Register extends React.Component {

    constructor() {
        super();

        this.state = {
            isSubmitting: false,
            isSuccess: false,
            isValid: false,
            form: {
                name: "",
                phone: "",
                password: "",
                confirmPassword: "",
                email: ""
            },
            errors: {}
        }

        this._onSubmit = this._onSubmit.bind(this)
        this._onChange = this._onChange.bind(this)
    }

    componentDidMount() {
        window.feather.replace()
    }

    _onChange(key, event) {
        const form = this.state.form
        form[key] = event.target.value

        this.setState({
            form: form
        })
    }

    _onSubmit() {
        this.setState({
            isSubmitting: false
        })

        const formData = new FormData();

        for (const [key, value] of Object.entries(this.state.form)) {
            formData.append(key, value)
        }

        formData.append('type', 1)

        axios.post(BASE_URL + "/register", formData)
            .then(response => {

                if (response.data.status) {
                    this.setState({
                        isSuccess: true,
                        isSubmitting: false,
                        errors: {}
                    })
                } else {
                    this.setState({
                        isSuccess: false,
                        isSubmitting: false,
                        errors: response.data.errors,
                    })
                }

            })
            .catch(error => {
                this.setState({
                    isSuccess: false,
                    isSubmitting: false,
                    errors: error.response.data.errors,
                })
            })
    }

    render() {
        return (
            <Fragment>
                <div className="back-to-home rounded d-none d-sm-block">
                    <Link to={"/"} className={"btn btn-icon btn-soft-primary"}><i data-feather="home" className="icons"></i></Link>
                </div>

                {/* <div class="modal fade show" id="LoginForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: 'block', paddingRight: 17, backgroundColor: 'rgba(0,0,0, 0.5)' }} aria-modal="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content rounded shadow border-0">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Pengumuman !</h5>
                            </div>
                            <div class="modal-body">
                                <div class="bg-white p-3 rounded box-shadow">
                                    <p class="text-muted mb-0">Untuk saat ini pendaftaran sedang ditutup, akan dibuka pada tanggal 1 November 2020.</p>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <Link to={"/"}><button type="button" class="btn btn-primary">Kembali</button></Link>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Register */}
                <section className="bg-home d-flex align-items-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-6">
                                <div className="mr-lg-5">
                                    <img src={SignUp} className="img-fluid d-block mx-auto" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <div className="card login_page shadow rounded border-0">
                                    <div className="card-body">
                                        <h4 className="card-title text-center">Daftar</h4>
                                        {
                                            Object.keys(this.state.errors).length ?
                                                <div className={"alert alert-danger"}>
                                                    <ul>
                                                        {
                                                            Object.keys(this.state.errors).map((value, index) => <li key={index}>{this.state.errors[value][0]}</li>)
                                                        }
                                                    </ul>
                                                </div> : null
                                        }
                                        {
                                            this.state.isSuccess ?
                                                <div className={"alert alert-success"}>
                                                    Sukses registrasi Silakan <Link to={"/login"}>Masuk</Link>
                                                </div> : null
                                        }
                                        <div className="login-form mt-4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group position-relative">
                                                        <label>Nama Lengkap </label>
                                                        <i data-feather="user" className="fea icon-sm icons"></i>
                                                        <input type="text" className="form-control pl-5" placeholder="Nama" value={this.state.form.name} onChange={(event) => { this._onChange('name', event) }} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group position-relative">
                                                        <label>Email </label>
                                                        <i data-feather="mail" className="fea icon-sm icons"></i>
                                                        <input type="email" className="form-control pl-5" placeholder="Email" name="email" value={this.state.form.email} onChange={(event) => { this._onChange('email', event) }} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group position-relative">
                                                        <label>Nomor Handphone </label>
                                                        <i data-feather="phone" className="fea icon-sm icons"></i>
                                                        <input type="text" className="form-control pl-5" placeholder="Nomor Handphone" name="phone" value={this.state.form.phone} onChange={(event) => { this._onChange('phone', event) }} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group position-relative">
                                                        <label>Password </label>
                                                        <i data-feather="key" className="fea icon-sm icons"></i>
                                                        <input type="password" className="form-control pl-5" placeholder="Password" name="s" value={this.state.form.password} onChange={(event) => { this._onChange('password', event) }} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group position-relative">
                                                        <label>Konfirmasi Password </label>
                                                        <i data-feather="key" className="fea icon-sm icons"></i>
                                                        <input type="password" className="form-control pl-5" placeholder="Konfirmasi Password" name="s" value={this.state.form.confirmPassword} onChange={(event) => { this._onChange('confirmPassword', event) }} required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group position-relative">
                                                        <ReCAPTCHA
                                                            sitekey="6LdJ4doZAAAAABgQI1Ha0ok-E4ESn3ABOaIUAr1I"
                                                            onChange={(value) => {
                                                                console.log(typeof value)
                                                                if (typeof value === "string") {
                                                                    console.log("masuk")
                                                                    this.setState({
                                                                        isValid: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <div className="col-md-12">
                                                    <button className="btn btn-primary btn-block" disabled={this.state.isSubmitting || !this.state.isValid} onClick={this._onSubmit}>Daftar</button>
                                                </div>
                                                <div className="mx-auto">
                                                    <p className="mb-0 mt-3"><small className="text-dark mr-2">Apakah Anda sudah memiliki akun ?</small> <Link to={"/login"} className={"text-dark font-weight-bold"}> Masuk </Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default Register