import React, { createContext, useState, useEffect } from 'react'

import Axios from 'axios'
import { TOKEN_ADMIN, BASE_URL, TOKEN_KEY, ACCOUNT_KEY } from '../Constants/constant'

export const AdminContext = createContext()
export const AdminContextConsumer = AdminContext.Consumer

const AdminContextProvider = ({ children }) => {
    const [admin, setAdmin] = useState(null)
    const [accountID, setAccountID] = useState(null)
	const [account, setAccount] = useState({})
    const [active, setActive] = useState(0)
    const [selectedVoucher, setSelectedVoucher] = useState(null)

    const fetchAdmin = () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            if (localStorage.getItem(ACCOUNT_KEY)) {
                setAccount(JSON.parse(localStorage.getItem(ACCOUNT_KEY)))
            }

            Axios.post(BASE_URL + "/account", null, {
                headers: {
                    Role: 'SuperAdministrator',
                    Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
                }
            }).then(response => {
                if (response.data.status) {
                    localStorage.setItem(ACCOUNT_KEY, JSON.stringify(response.data.data))

                    setAdmin(JSON.parse(localStorage.getItem(ACCOUNT_KEY)))
                } else {
                    window.location.href = "/login"
                }
            }).catch(err => {
                window.location.href = "/login"
            })
        }
    }

    const fetchAccount = () => {
        if(accountID) {
            const formData = new FormData()
            formData.append('accountID', accountID)
    
            Axios.post(BASE_URL + "/account", formData, {
                headers: {
                    Role: "SuperAdministrator",
                    Authorization: "Bearer " + TOKEN_ADMIN
                }
            }).then(response => {
                if (response.data.status) {
                    setAccount(response.data.data)
                }
            })
        }
    }

	useEffect(() => {
        fetchAccount()
    }, [accountID])

    useEffect(() => {
        
    }, [account])

	return <AdminContext.Provider value={{
        fetchAccount,
        fetchAdmin,

        admin,
        setAdmin,

        accountID,
        setAccountID,

        setAccount,
        account,

        selectedVoucher, 
        setSelectedVoucher,

        active,
        setActive
     }}>{children}</AdminContext.Provider>
}

export default AdminContextProvider