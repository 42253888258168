import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { Line, Pie } from '@reactchartjs/react-chart.js'


function Dashboard({ history }) {

    useEffect(() => {
        window.feather.replace()
    }, [])

    const [data, setData] = useState(null)

    const fetchData = () => {
        Axios.get(BASE_URL + "/resume", {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(response => {
            if (response.data.status) {
                setData(response.data.data)
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }


    return (
        <Fragment>
            <h4>Dashboard</h4>
            <div>
                {
                    data ?
                        <div>
                            <p style={{ textAlign: 'center' }}>
                                <h5>total Pendaftar : {data.total}</h5>
                            </p>
                            <hr/>
                            <Line data={{
                                labels: Object.keys(data.dates),
                                datasets: [
                                    {
                                        label: data.total + ' Pendaftar',
                                        data: Object.values(data.dates),
                                        fill: false,
                                        backgroundColor: 'rgb(255, 99, 132)',
                                        borderColor: 'rgba(255, 99, 132, 0.2)',
                                    },
                                ],
                            }} options={options} />
                            <hr />
                        </div> : null
                }
            </div>
        </Fragment>
    )
}

export default Dashboard