import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AdminContext } from '../../../../Context/AdminContext'


function DetailVoucher({ history }) {

    const adminContext = useContext(AdminContext)

    const {
        setAccountID,
        setActive,
        selectedVoucher
    } = adminContext

    useEffect(() => {
        window.feather.replace()
    }, [])

    const [accounts, setAccounts] = useState([])

    const fetchAccount = () => {
        Axios.post(BASE_URL + "/redeem-voucher/" + selectedVoucher.ID, null, {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(response => {
            if (response.data.status) {
                setAccounts(response.data.data.list)
            }
        })
    }

    useEffect(() => {
        fetchAccount()
    }, [])

    const detailAccount = (accountID) => {
        setAccountID(accountID)
        setActive(2)
    }

    const renderStatus = (account, type, label) => {
        if (account.uncompletedSteps[type]) {
            return <button class="btn btn-pills btn-success mt-2 mr-2">{label}</button>
        } else {
            return <button class="btn btn-pills btn-warning mt-2 mr-2">{label}</button>
        }
    }

    return (
        <Fragment>
            <h4>Daftar Penugguna Voucher {selectedVoucher?.name}</h4>
            <div class="table-responsive bg-white shadow rounded">
                <table class="table mb-0 table-center">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nama</th>
                            <th scope="col">Email</th>
                            <th scope="col">Nomor Telepon</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            accounts.map((account, index) =>
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <th scope="col" onClick={() => { detailAccount(account.ID) }}><a href={"#"}>{account.name}</a></th>
                                    <th scope="col">{account.email}</th>
                                    <th scope="col">{account.phone}</th>
                                    <th scope="col" style={{ minWidth: 400 }}>
                                        {renderStatus(account, "confirmed", "Pembayaran")}
                                        {renderStatus(account, "form", "Formulir")}
                                        <button className={"btn btn-pills mt-2 mr-2" + (account.registrant?.type == 1 ?
                                            (
                                                account.uncompletedSteps.registrantDocument && account.uncompletedSteps.reportScore && account.uncompletedSteps.reportDocument ? " btn-success" : " btn-warning"
                                            ) : (account.uncompletedSteps.registrantDocument ? " btn-success" : " btn-warning"))}>Upload Berkas</button>
                                        {renderStatus(account, "documentConfirmed", "Konfirmasi Dokumen")}
                                        {renderStatus(account, "reregistration", "Daftar Ulang")}
                                        {renderStatus(account, "registrationConfirmed", "Konfirmasi Daftar Ulang")}
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default DetailVoucher