import React, { Fragment, useEffect, useState } from 'react'
import AdminLayout from '../../../Layouts/AdminLayout'


function Profile({ history }) {

    useEffect(() => {
        window.feather.replace()
    }, [])

    return (
        <Fragment>
            <AdminLayout>
                <div>
                </div>
            </AdminLayout>
        </Fragment>
    )
}

export default Profile