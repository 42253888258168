import React, { Fragment, useContext, useEffect } from 'react'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AppContext } from '../../../../Context/AppContext'

import ApprovalImage from './../../../../Assets/Images/approval.svg'

function Approval({ history }) {

    const appContext = useContext(AppContext)

    const {
        account,
    } = appContext

    useEffect(() => {
        window.feather.replace()
    }, [])

    const getRegistrantCard = () => {
        return BASE_URL + '/student-card?token=' + localStorage.getItem(TOKEN_KEY);
    }

    return (
        <Fragment>
            <div>
                {
                    !(account.uncompletedSteps?.passedTest) ? (
                        !(account.uncompletedSteps?.documentConfirmed) ?
                            <h5>Kamu telah melengkapi formulir dan mengunggah berkas pendaftaran. Silakan menunggu konfirmasi dokumen untuk mendapatkan kartu peserta PPDB SMK Telkom Sidoarjo.</h5> :
                            <h5>Berkas kamu telah terkonfirmasi. Kamu telah memasuki tahap seleksi Gelombang 1 PPDB SMK Telkom Sidoarjo. Silakan buka halaman ini secara berkala untuk mengetahui hasil dari seleksi. <a href={getRegistrantCard()}>Unduh Kartu Peserta</a> </h5>
                    ) : (
                            <div class="modal fade show" id="LoginForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: 'block', paddingRight: 17, backgroundColor: 'rgba(0,0,0, 0.5)' }} aria-modal="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content rounded shadow border-0">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalCenterTitle">Pengumuman !</h5>
                                        </div>
                                        <div class="modal-body">
                                            <div class="bg-white p-3 rounded box-shadow">
                                                <p class="text-muted mb-0">Selamat buat {account.name} telah lolos dalam tahap seleksi PPDB SMK Telkom Sidoarjo untuk tahun ajaran 2021/2022. Silakan melakukan pembayaran Daftar Ulang untuk melakukan daftar ulang.</p>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary">Bayar Sekarang</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                }


                <p style={{ textAlign: 'center', marginTop: 50 }}>
                    <img src={ApprovalImage} style={{ width: '60%' }} />
                </p>
            </div>
        </Fragment>
    )
}

export default Approval