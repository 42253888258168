import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'


import SignUp from './../../../Assets/Images/user/signup.svg';
import { ACCOUNT_KEY, BASE_URL, TOKEN_KEY } from '../../../Constants/constant';
import { toast } from 'react-toastify';

function Login({ history }) {

    useEffect(() => {
        // window.feather.replace()
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activePage, setActivePage] = useState(1)
    const [email, setEmail] = useState(null)
    const [form, setForm] = useState({
        code: "",
        password: "",
        confirmPassword: "",
    })
    const [errors, setErrors] = useState({})

    const resetPassword = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value)
        }

        axios.post(BASE_URL + "/reset-password", formData)
            .then(response => {

                if (response.data.status) {
                    toast.success("Success mengganti password")

                    history.push("/login")

                    setIsSubmitting(false)
                    setErrors({})
                } else {
                    setIsSubmitting(false)
                    setErrors(response.data.errors)
                }

            })
            .catch(error => {
                setIsSubmitting(false)
                setErrors(error.response.data.errors)
            })
    }

    const forgetPassword = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        formData.append('email', email)

        axios.post(BASE_URL + "/forget-password", formData)
            .then(response => {

                if (response.data.status) {
                    toast.success("Silakan check email Anda")

                    setActivePage(2)

                    setIsSubmitting(false)
                    setErrors({})
                } else {
                    setIsSubmitting(false)
                    setErrors(response.data.errors)
                }

            })
            .catch(error => {
                setIsSubmitting(false)
                setErrors(error.response.data.errors)
            })
    }

    return (
        <Fragment>
            <div className="back-to-home rounded d-none d-sm-block">
                <Link to={"/"} className={"btn btn-icon btn-soft-primary"}><i data-feather="home" className="icons"></i></Link>
            </div>

            {/* Register */}
            <section className="bg-home d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6">
                            <div className="mr-lg-5">
                                <img src={SignUp} className="img-fluid d-block mx-auto" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="card login_page shadow rounded border-0">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Lupa Password</h4>
                                    {
                                        Object.keys(errors).length ?
                                            <div className={"alert alert-danger"}>
                                                <ul>
                                                    {
                                                        Object.keys(errors).map((value, index) => <li key={index}>{errors[value][0]}</li>)
                                                    }
                                                </ul>
                                            </div> : null
                                    }
                                    <div className="login-form mt-4">
                                        {
                                            activePage == 1 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group position-relative">
                                                            <label>Email <span className="text-danger">*</span></label>
                                                            <i data-feather="email" className="fea icon-sm icons"></i>
                                                            <input type="text" className="form-control pl-5" value={email} onChange={event => { setEmail(event.target.value) }} placeholder="Email" name="email" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={forgetPassword}>Reset Password</button>
                                                    </div>
                                                </div> :
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group position-relative">
                                                            <label>Kode <span className="text-danger">*</span></label>
                                                            <i data-feather="email" className="fea icon-sm icons"></i>
                                                            <input type="text" className="form-control pl-5" value={form.code} onChange={event => { setForm({ ...form, code: event.target.value }) }} placeholder="Kode" name="email" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group position-relative">
                                                            <label>Password </label>
                                                            <i data-feather="key" className="fea icon-sm icons"></i>
                                                            <input type="password" className="form-control pl-5" placeholder="Password" name="s" value={form.password} onChange={(event) => { setForm({ ...form, password: event.target.value }) }} required="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group position-relative">
                                                            <label>Konfirmasi Password </label>
                                                            <i data-feather="key" className="fea icon-sm icons"></i>
                                                            <input type="password" className="form-control pl-5" placeholder="Konfirmasi Password" name="s" value={form.confirmPassword} onChange={(event) => { setForm({ ...form, confirmPassword: event.target.value }) }} required="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={resetPassword}>Reset Password</button>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Login