import React, { Fragment, useContext, useEffect, useState } from 'react'
import Logo from './../../../Assets/Images/logo.png';
import LogoWhite from './../../../Assets/Images/logo_white.png';
import { Link, useLocation } from 'react-router-dom'
import { AppContext } from '../../../Context/AppContext';
import { ACCOUNT_KEY } from '../../../Constants/constant';

function Header() {

    
    const path = useLocation().pathname

    const [imageNavbar, setImageNavbar] = useState(Logo)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {

        const rawAccount = localStorage.getItem(ACCOUNT_KEY)
        if(rawAccount) {
            const account = JSON.parse(rawAccount)

            if(account.type == 1) {
                setIsLoggedIn(true)
            }
        }

        window.$('.navbar-toggle').on('click', function (event) {
            window.$(this).toggleClass('open');
            window.$('#navigation').slideToggle(400);
        });

        window.$('.navigation-menu>li').slice(-1).addClass('last-elements');

        window.$('.menu-arrow,.submenu-arrow').on('click', function (e) {
            if (window.$(window).width() < 992) {
                e.preventDefault();
                window.$(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });

        // Clickable Menu
        window.$(".has-submenu a").click(function () {
            if (window.innerWidth < 992) {
                if (window.$(this).parent().hasClass('open')) {
                    window.$(this).siblings('.submenu').removeClass('open');
                    window.$(this).parent().removeClass('open');
                } else {
                    window.$(this).siblings('.submenu').addClass('open');
                    window.$(this).parent().addClass('open');
                }
            }
        });

        window.$('.mouse-down').on('click', function (event) {
            var $anchor = window.$(this);
            window.$('html, body').stop().animate({
                scrollTop: window.$(window.$anchor.attr('href')).offset().top - 72
            }, 1500, 'easeInOutExpo');
            event.preventDefault();
        });

        //Sticky
        window.$(window).scroll(function () {
            var scroll = window.$(window).scrollTop();

            if (scroll >= 50) {
                window.$(".sticky").addClass("nav-sticky");
            } else {
                window.$(".sticky").removeClass("nav-sticky");
            }
        });
    }, [])

    return (
        <Fragment>
            <header id="topnav" class="defaultscroll sticky">
                <div class="container">
                    <div>
                        <a class="logo" href="/">
                            <img src={imageNavbar} height="24" alt="" />
                        </a>
                    </div>
                    <div class="buy-button">
                        {
                            isLoggedIn ?
                                <Link to={"/profile"} className={"btn btn-primary"}>Profile</Link> :
                                <Link to={"/register"} className={"btn btn-primary"}>Daftar</Link>
                        }
                    </div>

                    <div class="menu-extras">
                        <div class="menu-item">
                            <a class="navbar-toggle">
                                <div class="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div id="navigation">
                        <ul class="navigation-menu">
                            <li>
                                <Link to={"/"}>Home</Link>
                            </li>
                            {/* <li>
                                <Link to={"/about"}>About Us</Link>
                            </li> */}
                            <li>
                                <Link to={"/contact"}>Contact</Link>
                            </li>
                        </ul>
                        <div class="buy-menu-btn d-none">
                            {
                                isLoggedIn ?
                                    <Link to={"/profile"} className={"btn btn-primary"}>Profile</Link> :
                                    <Link to={"/register"} className={"btn btn-primary"}>Daftar</Link>
                            }
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default Header