import React, { Fragment } from 'react'

function NotFound() {
    return (
        <Fragment>
            <h1>Not Found</h1>
        </Fragment>
    )
}

export default NotFound