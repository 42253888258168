import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AdminContext } from '../../../../Context/AdminContext'


function DetailAccount() {

    const adminContext = useContext(AdminContext)

    const {
        fetchAccount,
        account,
        setActive
    } = adminContext

    useEffect(() => {
        window.feather.replace()
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activePage, setActivePage] = useState(1)

    const confirmDocument = (registrantID) => {
        setIsSubmitting(true)

        const formData = new FormData()
        formData.append("ID", registrantID)

        Axios.post(BASE_URL + "/confirm-document", formData, {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(responose => {
            fetchAccount()
            setIsSubmitting(false)
        }).catch(error => {
            setIsSubmitting(false)
        })
    }

    const confirmRegistration = (registrantID) => {
        setIsSubmitting(true)

        const formData = new FormData()
        formData.append("ID", registrantID)

        Axios.post(BASE_URL + "/confirm-registration", formData, {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(responose => {
            fetchAccount()
            setIsSubmitting(false)
        }).catch(error => {
            setIsSubmitting(false)
        })
    }

    const confirmExam = (registrantID) => {
        setIsSubmitting(true)

        const formData = new FormData()
        formData.append("ID", registrantID)

        Axios.post(BASE_URL + "/confirm-exam", formData, {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(responose => {
            fetchAccount()
            setIsSubmitting(false)
        }).catch(error => {
            setIsSubmitting(false)
        })
    }

    const confirmAccount = (registrantID) => {
        setIsSubmitting(true)

        const formData = new FormData()
        formData.append("ID", registrantID)

        Axios.post(BASE_URL + "/confirm-account", formData, {
            headers: {
                Role: "SuperAdministrator",
                Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY)
            }
        }).then(responose => {
            fetchAccount()
            setIsSubmitting(false)
        }).catch(error => {
            setIsSubmitting(false)
        })
    }

    console.log(account)

    return (
        <Fragment>
            <a href="#" style={{ fontSize: 40 }} onClick={() => { setActive(1) }}><i data-feather="arrow-left" class="fea icon-sm icons"></i></a>
            <br />
            <div className={"row"}>
                <div className="col-md-12">
                    <h4>Data Pendaftar</h4>
                </div>
                <div className={"col-md-12"}>
                    <hr />
                    <div>
                        {
                            !account.uncompletedSteps?.confirmed ?
                                <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmAccount(account.ID) }}>Confirm Registrant</button> :
                                <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmAccount(account.ID) }}>Uncomfirm Registrant</button>
                        }
                        {
                            account.registrant ?
                                <div>
                                    {
                                        !account.uncompletedSteps?.documentConfirmed ?
                                            <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmDocument(account.registrant.ID) }}>Confirm Document</button> :
                                            <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmDocument(account.registrant.ID) }}>Unconfirm Document</button>
                                    }
                                    {
                                        !account.uncompletedSteps?.passedTest ?
                                            <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmExam(account.registrant.ID) }}>Confirm Test</button> :
                                            <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmExam(account.registrant.ID) }}>Unconfirm Test</button>
                                    }
                                    {
                                        !account.uncompletedSteps?.registrationConfirmed ?
                                            <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmRegistration(account.registrant.ID) }}>Confirm Registration</button> :
                                            <button className={"btn btn-pills mt-0 mr-0 btn-info"} onClick={() => { confirmRegistration(account.registrant.ID) }}>Uncomfirm Registration</button>
                                    }
                                </div> : null
                        }
                    </div>
                </div>
            </div>

            <hr />

            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 1 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(1) }}>Info Pendaftar</button>
            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 2 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(2) }}>Berkas</button>
            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 3 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(3) }}>Informasi Dasar</button>
            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 4 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(4) }}>Informasi Lokasi</button>
            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 5 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(5) }}>Informasi Ayah</button>
            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 6 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(6) }}>Informasi Ibu</button>
            <button className={"btn btn-pills mt-2 mr-2 " + (activePage == 7 ? "btn-danger" : "btn-success")} onClick={() => { setActivePage(7) }}>Informasi Wali</button>

            <div class="table-responsive bg-white shadow rounded">
                {
                    account.registrant ?
                        <div>
                            {
                                activePage == 1 ?
                                    <div>
                                        <br /><br />
                                        <h4>Info Pendaftar</h4>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Pendafatan Jalur</label>
                                                    <select class="form-control custom-select" disabled={true} value={account?.registrant.type}>
                                                        <option value="1">Rapor</option>
                                                        <option value="2">Ujian</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Gender</label>
                                                    <select class="form-control custom-select" disabled={true} value={account?.registrant.gender}>
                                                        <option value="1">Laki Laki</option>
                                                        <option value="2">Perempuan</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Tanggal Lahir</label>
                                                    <input type="date" className="form-control" disabled={true} value={account?.registrant.bornDate} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Nama Orang Tua</label>
                                                    <input type="text" className="form-control" placeholder={"Nama Orang Tua"} disabled={true} value={account?.registrant.parentName} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Alamat</label>
                                                    <textarea type="text" className="form-control" disabled={true} value={account?.registrant.address} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <h4>Info Sekolah</h4>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Nama Sekolah</label>
                                                    <input type="text" className="form-control" placeholder={"Nama Sekolah"} disabled={true} value={account?.registrant.school.name} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Kecamatan Sekolah</label>
                                                    <input type="text" className="form-control" placeholder={"Kecamatan Sekolah"} disabled={true} value={account?.registrant.school.district} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Kota Sekolah</label>
                                                    <input type="text" className="form-control" placeholder={"Kota Sekolah"} disabled={true} value={account?.registrant.school.city} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Provinsi Sekolah</label>
                                                    <input type="text" className="form-control" placeholder={"Provinsi Sekolah"} disabled={true} value={account?.registrant.school.province} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <h4>Informasi Sekolah</h4>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Dari mana mengetahui Informasi SMK Telkom Sidoarjo?</label>
                                                    <select class="form-control custom-select" disabled={true} value={account?.registrant.registrantInfo.info}>
                                                        <option value="Orang tua peserta didik lain">Orang tua peserta didik lain</option>
                                                        <option value="Saudara">Saudara</option>
                                                        <option value="Kakak Tingkat">Kakak Tingkat</option>
                                                        <option value="Brosur/Poster">Brosur/Poster</option>
                                                        <option value="Website">Website</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="SMS ads">SMS ads</option>
                                                        <option value="Facebook">Facebook</option>
                                                        <option value="Tiktok">Tiktok</option>
                                                        <option value="Berita Online">Berita Online</option>
                                                        <option value="Lainnya">Lainnya</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group position-relative">
                                                    <label>Alasan Masuk SMK Telkom Sidoarjo</label>
                                                    <textarea type="text" className="form-control" disabled={true} value={account?.registrant.registrantInfo.reason} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            <hr />
                            {
                                activePage == 2 ?
                                    <div>
                                        <h4>Berkas Dasar</h4>
                                        <div>
                                            <div className="row">
                                                {
                                                    account.registrant.registrantDocuments.map((registrantDocument, index) =>
                                                        <div className="col-md-6" key={index}>
                                                            <a target="_blank" href={registrantDocument.URL}><button class="btn btn-pills btn-primary mt-2 mr-2">{registrantDocument.label}</button></a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            {
                                                account?.registrant.type == 1 ?
                                                    <div>
                                                        <hr />
                                                        <h4>Berkas Raport</h4>
                                                        <div>

                                                            {
                                                                account.registrant.reportDocuments.length > 0 ?
                                                                    <div className="row">
                                                                        {
                                                                            account.registrant.reportDocuments.map((reportDocument, index) =>
                                                                                <div className="col-md-6" key={index}>
                                                                                    <a target="_blank" href={reportDocument.URL}><button class="btn btn-pills btn-primary mt-2 mr-2">{reportDocument.label}</button></a>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div> : <div style={{ textAlign: 'center' }}>
                                                                        <h5>Belum Mengisi</h5>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <hr />
                                                        <h4>Nilai Raport</h4>
                                                        <div>
                                                            {
                                                                account.registrant.reportScores.length > 0 ?
                                                                    <div className="row">
                                                                        {
                                                                            account.registrant.reportScores.map((reportScore, index) =>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group position-relative">
                                                                                        <label>{reportScore.label}</label>
                                                                                        <input type="text" className="form-control" disabled={true} value={reportScore.score} />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    : <div style={{ textAlign: 'center' }}>
                                                                        <h5>Belum Mengisi</h5>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div> : null
                                            }
                                        </div>
                                    </div> : null
                            }

                            {
                                activePage == 3 ?
                                    <div>
                                        {
                                            account.registrant?.registrantInformation ?
                                                <div>
                                                    <h4>Informasi Dasar</h4>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>NIK</label>
                                                                <input type="text" className="form-control" placeholder={"NIK"} disabled={true} value={account.registrant?.registrantInformation?.NIK} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Agama</label>
                                                                <select class="form-control custom-select" disabled={true} value={account.registrant?.registrantInformation?.religion} >
                                                                    <option value="Islam">Islam</option>
                                                                    <option value="Katolik">Katolik</option>
                                                                    <option value="Protestan">Protestan</option>
                                                                    <option value="Hindu">Hindu</option>
                                                                    <option value="Budha">Budha</option>
                                                                    <option value="Konghucu">Konghucu</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Tempat Lahir</label>
                                                                <input type="text" className="form-control" placeholder={"Tempat Lahir"} disabled={true} value={account.registrant?.registrantInformation?.bornPlace} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>NISN</label>
                                                                <input type="text" className="form-control" placeholder={"NISN"} disabled={true} value={account.registrant?.registrantInformation?.NISN} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>NIS</label>
                                                                <input type="text" className="form-control" placeholder={"NIS"} disabled={true} value={account.registrant?.registrantInformation?.NIS} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nomor Ujian</label>
                                                                <input type="text" className="form-control" placeholder={"Nomor Ujian"} disabled={true} value={account.registrant?.registrantInformation?.examNumber} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Tinggi Badan</label>
                                                                <input type="text" className="form-control" placeholder={"Tinggi Badan"} disabled={true} value={account.registrant?.registrantInformation?.height} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Berat Badan</label>
                                                                <input type="text" className="form-control" placeholder={"Berat Badan"} disabled={true} value={account.registrant?.registrantInformation?.weight} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Jumlah Saudara</label>
                                                                <input type="text" className="form-control" placeholder={"Jumlah Saudara"} disabled={true} value={account.registrant?.registrantInformation?.sibling} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Anak Urutan Ke</label>
                                                                <input type="text" className="form-control" placeholder={"Anak Urutan ke"} disabled={true} value={account.registrant?.registrantInformation?.childNumber} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <div style={{ textAlign: 'center' }}>
                                                    <h4>Belum Mengisi</h4>
                                                </div>
                                        }
                                    </div> : null
                            }

                            {
                                activePage == 4 ?
                                    <div>
                                        {
                                            account.registrant?.registrantLocation ?
                                                <div>
                                                    <h4>Informasi Lokasi</h4>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>RT</label>
                                                                <input type="text" className="form-control" placeholder={"RT"} disabled={true} value={account.registrant?.registrantLocation?.RT} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>RW</label>
                                                                <input type="text" className="form-control" placeholder={"RW"} disabled={true} value={account.registrant?.registrantLocation?.RW} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Desa/Kelurahan</label>
                                                                <input type="text" className="form-control" placeholder={"Desa/Kelurahan"} disabled={true} value={account.registrant?.registrantLocation?.village} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Kecamatan</label>
                                                                <input type="text" className="form-control" placeholder={"Kecamatan"} disabled={true} value={account.registrant?.registrantLocation?.district} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Kota</label>
                                                                <input type="text" className="form-control" placeholder={"Kota"} disabled={true} value={account.registrant?.registrantLocation?.city} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Kode Pos</label>
                                                                <input type="text" className="form-control" placeholder={"Kode Pos"} disabled={true} value={account.registrant?.registrantLocation?.zipCode} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Tinggal Bersama</label>
                                                                <select class="form-control custom-select" disabled={true} value={account.registrant?.registrantLocation?.livingWith} >
                                                                    <option value="Orang Tua">Orang Tua</option>
                                                                    <option value="Saudara">Saudara</option>
                                                                    <option value="Orang Lain">Orang Lain</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Transporatsi</label>
                                                                <input type="text" className="form-control" placeholder={"Transportasi"} disabled={true} value={account.registrant?.registrantLocation?.transportaion} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Jarak Tempuh Ke Sekolah (KM)</label>
                                                                <input type="text" className="form-control" disabled={true} value={account.registrant?.registrantLocation?.distance} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Waktu Ke Sekolah (Menit)</label>
                                                                <input type="text" className="form-control" disabled={true} value={account.registrant?.registrantLocation?.time} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <div style={{ textAlign: 'center' }}>
                                                    <h4>Belum Mengisi</h4>
                                                </div>
                                        }

                                    </div> : null
                            }

                            {
                                activePage == 5 ?
                                    <div>
                                        {
                                            account.registrant?.registrantFather ?
                                                <div>
                                                    <h4>Informasi Ayah</h4>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nama</label>
                                                                <input type="text" className="form-control" placeholder={"Nama"} disabled={true} value={account.registrant?.registrantFather?.name} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>NIK</label>
                                                                <input type="text" className="form-control" placeholder={"NIK"} disabled={true} value={account.registrant?.registrantFather?.NIK} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Tanggal Lahir</label>
                                                                <input type="date" className="form-control" disabled={true} value={account.registrant?.registrantFather?.bornDate} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Pendidikan Terakhir</label>
                                                                <input type="text" className="form-control" placeholder={"Pendidikan Terakhir"} disabled={true} value={account.registrant?.registrantFather?.lastEducation} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Pekerjaan</label>
                                                                <input type="text" className="form-control" placeholder={"Pekerjaan"} disabled={true} value={account.registrant?.registrantFather?.job} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Gaji</label>
                                                                <input type="text" className="form-control" placeholder={"Gaji"} disabled={true} value={account.registrant?.registrantFather?.salary} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nomor Telepon</label>
                                                                <input type="text" className="form-control" placeholder={"Nomor Telepon"} disabled={true} value={account.registrant?.registrantFather?.phone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <div style={{ textAlign: 'center' }}>
                                                    <h4>Belum Mengisi</h4>
                                                </div>
                                        }
                                    </div> : null
                            }

                            {
                                activePage == 6 ?
                                    <div>
                                        {
                                            account.registrant?.registrantMother ?
                                                <div>
                                                    <h4>Informasi Ibu</h4>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nama</label>
                                                                <input type="text" className="form-control" placeholder={"Nama"} disabled={true} value={account.registrant?.registrantMother?.name} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>NIK</label>
                                                                <input type="text" className="form-control" placeholder={"NIK"} disabled={true} value={account.registrant?.registrantMother?.NIK} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Tanggal Lahir</label>
                                                                <input type="date" className="form-control" disabled={true} value={account.registrant?.registrantMother?.bornDate} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Pendidikan Terakhir</label>
                                                                <input type="text" className="form-control" placeholder={"Pendidikan Terakhir"} disabled={true} value={account.registrant?.registrantMother?.lastEducation} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Pekerjaan</label>
                                                                <input type="text" className="form-control" placeholder={"Pekerjaan"} disabled={true} value={account.registrant?.registrantMother?.job} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Gaji</label>
                                                                <input type="text" className="form-control" placeholder={"Gaji"} disabled={true} value={account.registrant?.registrantMother?.salary} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nomor Telepon</label>
                                                                <input type="text" className="form-control" placeholder={"Nomor Telepon"} disabled={true} value={account.registrant?.registrantMother?.phone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <div style={{ textAlign: 'center' }}>
                                                    <h4>Belum Mengisi</h4>
                                                </div>
                                        }

                                    </div> : null
                            }


                            {
                                activePage == 7 ?
                                    <div>
                                        {
                                            account.registrant?.registrantGuardian ?
                                                <div>
                                                    <br /><br />
                                                    <h4>Informasi Wali</h4>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nama</label>
                                                                <input type="text" className="form-control" placeholder={"Nama"} disabled={true} value={account.registrant?.registrantGuardian?.name} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>NIK</label>
                                                                <input type="text" className="form-control" placeholder={"NIK"} disabled={true} value={account.registrant?.registrantGuardian?.NIK} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Tanggal Lahir</label>
                                                                <input type="date" className="form-control" disabled={true} value={account.registrant?.registrantGuardian?.bornDate} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Pendidikan Terakhir</label>
                                                                <input type="text" className="form-control" placeholder={"Pendidikan Terakhir"} disabled={true} value={account.registrant?.registrantGuardian?.lastEducation} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Pekerjaan</label>
                                                                <input type="text" className="form-control" placeholder={"Pekerjaan"} disabled={true} value={account.registrant?.registrantGuardian?.job} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Gaji</label>
                                                                <input type="text" className="form-control" placeholder={"Gaji"} disabled={true} value={account.registrant?.registrantGuardian?.salary} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group position-relative">
                                                                <label>Nomor Telepon</label>
                                                                <input type="text" className="form-control" placeholder={"Nomor Telepon"} disabled={true} value={account.registrant?.registrantGuardian?.phone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <div style={{ textAlign: 'center' }}>
                                                    <h4>Belum Mengisi</h4>
                                                </div>
                                        }
                                    </div> : null
                            }
                        </div> :
                        <div style={{ textAlign: 'center' }}>
                            <br /><br />
                            <h4>Belum Mengisi</h4>
                        </div>
                }
            </div>
        </Fragment>
    )
}

export default DetailAccount