import React, { Fragment, useContext, useEffect, useState } from 'react'

import Axios from 'axios'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { toast } from 'react-toastify'
import { AppContext } from '../../../../Context/AppContext'

function Home({ history }) {

    const appContext = useContext(AppContext)

    const {
        setActive,
    } = appContext

    return (
        <Fragment>
            <div>
                <h4>Daftar Gelombang Pendaftaran SMK Telkom Sidoarjo</h4>
                <br/>
                <table class="table mb-0 table-center">
                    <thead>
                        <tr>
                            <th scope="col">Jalur</th>
                            <th scope="col">Tanggal Dibuka</th>
                            <th scope="col">Tanggal Ditutup</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Gelombang 1</th>
                            <td>1 November 2020</td>
                            <td>8 Januari 2021</td>
                        </tr>
                        <tr>
                            <th scope="row">Gelombang 2</th>
                            <td>11 Januari 2021</td>
                            <td>5 Maret 2021</td>
                        </tr>
                    </tbody>
                </table>
                <hr/>
                <br/>
                <br/>
                <div className={"row"}>
                    <div className="col-md-12">
                        <button className="btn btn-primary btn-block" onClick={() => {
                            setActive(1)
                        }}>Lanjut ke Pembayaran</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Home