import React, { Fragment, useEffect } from 'react'
import Header from './Components/Header'
import Bitcoin from './../../../Assets/Images/icon/bitcoin.svg'
import Email from './../../../Assets/Images/icon/Email.svg'
import Location from './../../../Assets/Images/icon/location.svg'

function Contact() {
    useEffect(() => {
        window.feather.replace();
    }, [])

    return (
        <Fragment>
            <Header />

            <section class="section pb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card contact-detail text-center border-0">
                                <div class="card-body p-0">
                                    <div class="icon">
                                        <img src={Bitcoin} class="avatar avatar-small" alt="" />
                                    </div>
                                    <div class="content mt-3">
                                        <h4 class="title font-weight-bold">Phone</h4>
                                        <p class="text-muted">Silakan hubungi kami melalui Telephone</p>
                                        <a href="tel:+152534-468-854" class="text-primary">+62823-3666-4466</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="card contact-detail text-center border-0">
                                <div class="card-body p-0">
                                    <div class="icon">
                                        <img src={Email} class="avatar avatar-small" alt="" />
                                    </div>
                                    <div class="content mt-3">
                                        <h4 class="title font-weight-bold">Email</h4>
                                        <p class="text-muted">Hubungi kami melalui Email dengan alamt berikut</p>
                                        <a href="mailto:contact@example.com" class="text-primary">info@smktelkom-sda.sch.id</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="card contact-detail text-center border-0">
                                <div class="card-body p-0">
                                    <div class="icon">
                                        <img src={Location} class="avatar avatar-small" alt="" />
                                    </div>
                                    <div class="content mt-3">
                                        <h4 class="title font-weight-bold">Lokasi</h4>
                                        <p class="text-muted">Jl. Pecantingan, Sekardangan Indah, Sekardangan, Kabupaten Sidoarjo, Jawa Timur 61215</p>
                                        <a href="https://www.google.com/maps/place/SMK+TELKOM+Sidoarjo/@-7.4665522,112.7248785,15z/data=!4m2!3m1!1s0x0:0x4232ab0204ccbfe5?sa=X&ved=2ahUKEwjxwKu449bsAhXFWisKHaWPA8sQ_BIwEHoECBkQBQ" class="video-play-icon h6 text-primary">View on Google map</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid mt-100 mt-60">
                    <div class="row">
                        <div class="col-12 p-0">
                            <div class="card map border-0">
                                <div class="card-body p-0">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15823.952851839826!2d112.7248785!3d-7.4665522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4232ab0204ccbfe5!2sSMK%20TELKOM%20Sidoarjo!5e0!3m2!1sen!2sid!4v1603870695555!5m2!1sen!2sid" width="600" height="450" frameborder="0" style={{ border: 0 }} allowFullScreen={true} aria-hidden="false" tabindex="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="mb-4">TnC & Refund Policy</h4>
                                <p class="para-desc mx-auto text-muted">Berikut term and condition sekaligus refund policy pada layanan PPDB SMK Telkom Sidoarjo.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-9 mt-4 pt-2">
                            <div class="faq-content">
                                <div class="accordion" id="accordionExample">
                                    <div class="card border-0 rounded mb-2">
                                        <a data-toggle="collapse" href="#collapseOne" class="faq position-relative" aria-expanded="true" aria-controls="collapseOne">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingOne">
                                                <h5 class="title mb-0"> 1.	Condition of use </h5>
                                            </div>
                                        </a>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Ppdb.smktelkom-sda.sch.id adalah sebuah Sistem Layanan yang dirancang untuk memfasilitasi otomasi pelaksanaan Penerimaan Peserta Didik Baru (PPDB), dari proses pendaftaran, seleksi hingga pengumuman hasil seleksi berbasis waktu nyata melalui Internet.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-2">
                                        <a data-toggle="collapse" href="#collapseTwo" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapseTwo">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingTwo">
                                                <h5 class="title mb-0"> Overview </h5>
                                            </div>
                                        </a>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Penggunaan Anda atas Situs ini merupakan persetujuan Anda terhadap semua syarat, ketentuan, dan pemberitahuan. Harap baca dengan cermat. Dengan menggunakan Situs ini, Anda menyetujui Syarat dan Ketentuan ini, serta syarat, pedoman atau aturan lain yang berlaku untuk setiap bagian dari Situs ini, tanpa batasan atau kualifikasi.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-2">
                                        <a data-toggle="collapse" href="#collapseThree" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapseThree">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 3.	MODIFICATION OF THE SITE AND THESE TERMS & CONDITIONS </h5>
                                            </div>
                                        </a>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">SMK Telkom Sidoarjo berhak untuk mengubah, memodifikasi, mengubah, memperbarui atau menghentikan syarat, ketentuan, dan pemberitahuan di mana Situs ini ditawarkan dan tautan, konten, informasi, harga, dan materi lain yang ditawarkan melalui Situs ini kapan saja dan dari waktu ke waktu tanpa pemberitahuan atau kewajiban lebih lanjut kepada Anda kecuali sebagaimana ditentukan di dalamnya. Kami berhak menyesuaikan harga dari waktu ke waktu. Jika karena alasan tertentu mungkin ada kesalahan harga, SMK Telkom Sidoarjo berhak menolak pesanan. Dengan terus menggunakan Situs setelah modifikasi, perubahan, atau pembaruan tersebut, Anda setuju untuk terikat oleh modifikasi, perubahan, atau pembaruan tersebut.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 4.	COPYRIGHTS </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Situs ini dimiliki dan dioperasikan oleh SMK Telkom Sidoarjo. Kecuali ditentukan lain, semua materi di Situs ini, merek dagang, merek layanan, logo adalah milik MT Cleaning Services dan dilindungi oleh undang-undang hak cipta Indonesia dan, di seluruh dunia oleh undang-undang hak cipta yang berlaku. Tidak ada materi yang diterbitkan oleh SMK Telkom Sidoarjo  di Situs ini, secara keseluruhan atau sebagian, dapat disalin, direproduksi, dimodifikasi, diterbitkan ulang, diunggah, diposting, dikirim, atau didistribusikan dalam bentuk apa pun atau dengan cara apa pun tanpa izin tertulis sebelumnya dari SMK Telkom Sidoarjo.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 5.	SIGN UP </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Anda perlu mendaftar Situs ini untuk memesan layanan dengan memasukkan nama pengguna dan kata sandi Anda. Anda akan mendapatkan keuntungan seperti buletin, pembaruan, dan penawaran khusus dengan mendaftar. Anda akan diminta untuk memberikan informasi yang akurat dan terkini tentang semua formulir pendaftaran di Situs ini. Anda sepenuhnya bertanggung jawab untuk menjaga kerahasiaan nama pengguna dan kata sandi yang Anda pilih atau dipilih oleh administrator web Anda atas nama Anda, untuk mengakses Situs ini serta aktivitas apa pun yang terjadi dengan nama pengguna / kata sandi Anda. Anda tidak akan menyalahgunakan atau membagikan nama pengguna atau kata sandi Anda, salah menggambarkan identitas Anda atau afiliasi Anda dengan suatu entitas, menyamar sebagai orang atau entitas mana pun, atau salah menyatakan asal Materi yang Anda temukan melalui Situs ini.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 6.	SERVICES DESCRIPTIONS </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Kami selalu berusaha sebaik mungkin untuk menampilkan informasi di Situs seakurat mungkin. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 7.	PRIVACY POLICY </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Informasi Anda aman bersama kami. SMK Telkom Sidoarjo memahami bahwa masalah privasi sangat penting bagi pelanggan kami. Anda dapat yakin bahwa informasi apa pun yang Anda kirimkan kepada kami tidak akan disalahgunakan, disalahgunakan, atau dijual kepada pihak lain mana pun. Kami hanya menggunakan informasi pribadi Anda untuk menyelesaikan pesanan Anda.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 8.	INDEMNITY </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Anda setuju untuk mengganti rugi, membela, dan menahan SMK Telkom Sidoarjo dari dan terhadap setiap dan semua klaim, kewajiban, kerusakan, kerugian, atau pengeluaran pihak ketiga (termasuk biaya dan biaya pengacara yang wajar) yang timbul dari, berdasarkan atau sehubungan dengan akses Anda dan / atau penggunaan Situs ini.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 9.	DISCLAIMER </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Layanan SMK Telkom SIdoarjo bertanggung jawab atas keakuratan, kebenaran, ketepatan waktu, atau konten Materi yang disediakan di Situs ini. Anda tidak boleh berasumsi bahwa Materi di Situs ini terus diperbarui atau berisi informasi terkini. MT Cleaning Services tidak bertanggung jawab untuk menyediakan konten atau materi dari Situs yang telah kedaluwarsa atau telah dihapus.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 10.	APPLICABLE LAWS </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Syarat dan Ketentuan ini diatur oleh hukum yang berlaku di Indonesia.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 11.	QUESTIONS AND FEEDBACK </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Kami menyambut baik pertanyaan, komentar, dan kekhawatiran Anda tentang privasi atau informasi apa pun yang dikumpulkan dari Anda atau tentang Anda. Harap kirimkan setiap dan semua umpan balik yang berkaitan dengan privasi, atau masalah lainnya.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-0 rounded mb-0">
                                        <a data-toggle="collapse" href="#collapsefive" class="faq position-relative collapsed" aria-expanded="false" aria-controls="collapsefive">
                                            <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                                <h5 class="title mb-0"> 12. Refund Policy </h5>
                                            </div>
                                        </a>
                                        <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p class="text-muted mb-0 faq-ans">Jika terjadi kegagalan dalam aktivasi akun Anda, silakan hubungi administrator yang tertera pada laman paling atas. Pembayaran yang sudah masuk di kami tidak dapat dikembalikan.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br/><br/>
            </section>
        </Fragment>
    )
}

export default Contact