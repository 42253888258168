import React, { Fragment } from 'react'

import Marketing from './../../../../Assets/Images/marketing/marketing.png'
import MarketingShape from './../../../../Assets/Images/marketing/marketing-shape.png'

function Header() {
    return (
        <Fragment>

            <section class="bg-marketing d-table w-100" style={{
                background: `url(${MarketingShape})`
            }} id="home">
                <div class="container">
                    <div class="row justify-content-center mt-5">
                        <div>
                            <div class="text-center">
                                <img src={Marketing} class="img-fluid" style={{
                                    maxHeight: 400
                                }} alt="" />
                            </div>

                            <div class="title-heading mt-0 mt-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h1 class="heading mb-3 text-center">SMK Telkom Sidoarjo</h1>
                                <p class="text-muted text-indent">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Header;