import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AppContext } from '../../../../Context/AppContext'


function Payment({ history }) {
    const appContext = useContext(AppContext)

    const {
        account,
        fetchAccount
    } = appContext

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [paymentType, setPaymentType] = useState(1)
    const [voucherCode, setVoucherCode] = useState(null)
    const [isNotifVoucher, setIsNotifVoucher] = useState(false);

    useEffect(() => {
        window.feather.replace()
    }, [])

    const createPayment = () => {

        if (voucherCode == null || voucherCode == "") {
            setIsNotifVoucher(true);
            return;
        }

        setIsSubmitting(true)

        const formData = new FormData()
        formData.append('paymentType', paymentType)
        formData.append('code', voucherCode)

        Axios.post(BASE_URL + "/payment", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        }).then(response => {
            if (response.data.status) {
                fetchAccount()
            } else {
                Object.keys(response.data.errors).map((value) => {
                    toast.error(response.data.errors[value][0])
                })
            }

            setIsSubmitting(false)
        })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    return (
        <Fragment>
            <div>
                {
                    account.activePayment ?
                        <div>
                            <h4>Informasi Pembayaran</h4>
                            <br />
                            <hr />
                            <br />
                            {
                                account.activePayment.type != 8 ?
                                    <table>
                                        <tr>
                                            <td style={{ minWidth: 200 }}>Metode</td>
                                            <td>: {account.activePayment.label}</td>
                                        </tr>
                                        {
                                            account.activePayment.type == 9 ?
                                                <tr>
                                                    <td>QR-Code</td>
                                                    <td>: <img src={account.activePayment.paymentNumber.number} style={{ width: '50%' }} /></td>
                                                </tr> : (
                                                    account.activePayment.paymentNumber ?
                                                        <tr>
                                                            <td>Virtual Account</td>
                                                            <td>: {account.activePayment.paymentNumber.number}</td>
                                                        </tr> : null
                                                )
                                        }
                                        <tr>
                                            <td>Total</td>
                                            <td>: Rp. 100.000</td>
                                        </tr>
                                        <tr>
                                            <td>Expired</td>
                                            <td>: {account.activePayment.expired}</td>
                                        </tr>
                                    </table> :
                                    <table>
                                        <tr>
                                            <td style={{ minWidth: 200 }}>Metode</td>
                                            <td>: {account.activePayment.label}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>: Rp. 100.000</td>
                                        </tr>
                                        <tr>
                                            <td>Cara Pembayaran</td>
                                            <td>: Silakan langsunsg menuju SMK Telkom Sidoarjo dan melakukan pembayaran</td>
                                        </tr>
                                    </table>
                            }
                            <br />
                            <hr />
                            <br />
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" onClick={() => { fetchAccount() }}>Cek Status Pembayaran</button>
                            </div>
                        </div> :
                        <div>
                            <h4>Bayar Menggunakan</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pilih Bank?</label>
                                        <select class="form-control custom-select" onChange={(event) => { setPaymentType(event.target.value) }}>
                                            <option value="1">Bank Mandiri</option>
                                            <option value="2">Bank Permata</option>
                                            <option value="3">Bank BCA</option>
                                            <option value="3">Bank BNI</option>
                                            <option value="5">Indomart</option>
                                            <option value="6">Alfamart</option>
                                            <option value="9">GoPay</option>
                                            <option value="8">Pembayaran Manual</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Kode Voucher</label>
                                        <input type="text" className="form-control" placeholder={"Kode Voucher"} max={6} onChange={(event) => { setVoucherCode(event.target.value) }} />
                                    </div>
                                    {
                                        isNotifVoucher ?
                                            <div className={"alert alert-danger"}>
                                                Mohon masukkan kode voucher. Jika anda tidak memiliki kode voucher, silakan masukkan kode voucher berikut <b>JIDA44</b> untuk menggunakan kode voucher pendaftaran website.
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={createPayment}>Bayar Sekarang</button>
                            </div>
                        </div>
                }
            </div>
        </Fragment>
    )
}

export default Payment