import React, { Fragment, useEffect, useState } from 'react'
import Header from './Components/Header'

import SEO_SVG from './../../../Assets/Images/account/visi.png';
import Requirements from './../../../Assets/Images/account/requirements.png';
import ArtAndDesign from './../../../Assets/Images/icon/art-and-design.svg';
import Clock from './../../../Assets/Images/icon/clock.svg';

import TelkomIndonesia from './../../../Assets/Images/rekanan/telkom-indonesia.png';
import Infomedia from './../../../Assets/Images/rekanan/infomedia.png';
import TelkomMetra from './../../../Assets/Images/rekanan/telkom-metra.png';
import Huawei from './../../../Assets/Images/rekanan/huawei.png';

import TelkomUniversity from './../../../Assets/Images/rekanan/telkom-university.png';
import ITTelkomSurabaya from './../../../Assets/Images/rekanan/it-telkom-surabaya.png';
import ITTelkomPurwokerto from './../../../Assets/Images/rekanan/it-telkom-purwokerto.png';
import AkademiTelkomJakarta from './../../../Assets/Images/rekanan/akademi-telkom-jakarta.png';

import { BASE_URL, TOKEN_ADMIN } from '../../../Constants/constant';
import Axios from 'axios';

function Index() {

    const [todayRegistrant, setTodayRegistrant] = useState(0)

    const fetchData = () => {
        Axios.get(BASE_URL + "/resume", {
        }).then(response => {
            if (response.data.status) {
                setTodayRegistrant(response.data.data.total)
            }
        })
    }

    useEffect(() => {
        window.feather.replace()

        window.$('#customer-testi').owlCarousel({
            loop: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }
        });

        fetchData()
    }, [])



    return (
        <Fragment>
            <Header />

            {/* How It Works */}
            <section class="section bg-light border-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-6">
                            <img src={SEO_SVG} alt="" style={{ width: '100%' }} />
                        </div>

                        <div class="col-lg-7 col-md-6 mt-4 pt-2">
                            <div class="section-title ml-lg-5">
                                <h4 class="title mb-4">Kenapa memilih SMK Telkom Sidoarjo ?</h4>
                                <p class="text-muted"> <span class="text-primary font-weight-bold">SMK Telkom Sidoarjo</span> berada di bawah naungan Yayasan Pendidikan Telkom sebagai bentuk sumbangasih
                                PT Telkom Indonesia dalam bidang pendidikan. Sekolah yang berbasis ICT ini SIAP mencetak lulusan yang berkarakter unggul dan bertaraf internasional dengan kompetensi keahlian
                                TEKNIK JARINGAN AKSES TELEKOMUNIKASI.</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 mr-2">
                                        <i class="uim uim-check-circle"></i></span>Visi
                                        <p>
                                            Mewujudkan Lembaga Pendidikan menengah kejuruan yang bermutu dengan standar internasional, sehingga mampu menghasilkan lulusan yang berkarakter unggul.
                                        </p>
                                    </li>
                                    <li class="mb-0"><span class="text-primary h5 mr-2">
                                        <i class="uim uim-check-circle"></i></span>Misi
                                        <p>
                                            1. Menyelenggarakan Pendidikan menengah kejuruan yang bermutu dan secara bertahap dikembangkan untuk memenuhi standar internasional.
                                        </p>
                                        <p>
                                            2. Mengembangkan sistem pembinaan peserta didik untuk membentuk lulusan yang berkarakter unggul.
                                        </p>
                                        <p>
                                            3. Mengembangkan unit produksi yang relevan dengan program studi untuk meningkatkan skill dan kreativitas peserta didik.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Total Pendaftar */}
            <section class="section">
                <div class="container">
                    <h4 class="title mb-4 text-center">Statistik Pendaftaran SMK Telkom Sidoarjo</h4>
                    <div class="row mt-4 pt-2">
                        <div class="col-md-12 col-12 mt-4 pt-2">
                            <div class="counter-box text-center">
                                <h2 class="mb-0 mt-4"><span class="counter-value" data-count="97">{todayRegistrant}</span></h2>
                                <h6 class="counter-head text-muted">Total Pendaftar</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Testimoni */}

            {/* FAQ */}
            <section class="section bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="media">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary mr-2 mt-1"></i>
                                <div class="media-body">
                                    <h5 class="mt-0">Beasiswa <span class="text-primary">Telkom University</span></h5>
                                    <p class="answer text-muted mb-0">Program yang mempermudah peserta didik untuk melanjutkan Pendidikan yang lebih tinggi ke Universitas yang berada di bawah naungan Yayasan Pendidikan Telkom, seperti Telkom University, Institut Teknologi Telkom Surabaya, Institut Teknologi Telkom Purwokerto, dan Akademi Telkom Jakarta.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="media">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary mr-2 mt-1"></i>
                                <div class="media-body">
                                    <h5 class="mt-0"> Kelas Industri PT Telkom Indonesia</h5>
                                    <p class="answer text-muted mb-0">Mengadaptasi program VET (Vocational and  Education Training) peserta didik  diperkenalkan secara langsung tentang  budaya bekerja di dunia usaha atau industri.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="media">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary mr-2 mt-1"></i>
                                <div class="media-body">
                                    <h5 class="mt-0"> Digital Talent Program</h5>
                                    <p class="answer text-muted mb-0">Program hasil Kerjasama SMK Telkom  Sidoarjo dengan dunia industri untuk  menghasilkan lulusan yang ahli di industri  digital (desain, pemograman, dan bisnis).</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="media">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary mr-2 mt-1"></i>
                                <div class="media-body">
                                    <h5 class="mt-0"><span class="text-primary">SKOMDA</span> MAKERSPACE</h5>
                                    <p class="answer text-muted mb-0">SMK Telkom Sidoarjo bekerjasama dengan  Komunitas Kampung Robot sebagai  penyaluran bibit muda potensial ke industri.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Syarat Mendaftar */}
            <section class="section bg-light">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                            <div class="section-title">
                                <h1 class="title mb-3">Apa saja syarat pendaftaaran PPDB <span class="text-primary">SMK Telkom Sidoarjo</span>?</h1>
                                <p class="para-desc text-muted">
                                    <ul class="list-unstyled text-muted">
                                        <li class="mb-0"><span class="text-primary h5 mr-2">
                                            <i class="uim uim-check-circle"></i></span>Foto Berwarna
                                        </li>
                                        <li class="mb-0"><span class="text-primary h5 mr-2">
                                            <i class="uim uim-check-circle"></i></span>Scan Akta Kelahiran
                                        </li>
                                        <li class="mb-0"><span class="text-primary h5 mr-2">
                                            <i class="uim uim-check-circle"></i></span>Scan Kartu Keluarga
                                        </li>
                                        <li class="mb-0"><span class="text-primary h5 mr-2">
                                            <i class="uim uim-check-circle"></i></span>Scan Surat Keterangan Sehat & tidak Buta Warna
                                        </li>
                                        <li class="mb-0"><span class="text-primary h5 mr-2">
                                            <i class="uim uim-check-circle"></i></span>Scan Surat Keterangan Lulus/keterangan siswa  aktif di SMP
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-5 order-1 order-md-2">
                            <div class="app-feature-shape-right position-relative">
                                <div class="text-center text-md-right">
                                    <img src={Requirements} class="img-fluid" alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section overflow-hidden">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">Apa saja tahapan seleksi PPDB <span class="text-primary">SMK Telkom Sidoarjo</span>?</h4>
                                <p class="text-muted para-desc mx-auto mb-0">Berikut beberapa tahapan yang kamu lakukan ketika melakukan seleksi PPDB di SMK Telkom Sidoarjo.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="card text-center rounded border-0">
                                <div class="card-body">
                                    <div class="p-3 bg-light rounded shadow d-inline-block">
                                        <img src={ArtAndDesign} class="avatar avatar-small" alt="" />
                                    </div>
                                    <div class="mt-4">
                                        <h5><a href="javascript:void(0)" class="text-dark">Tes Akademik</a></h5>
                                        <p class="text-muted mt-3 mb-0">Diambil dari nilai rapor semester 1 s/d 4 dan berkas scan rapor.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="card text-center rounded border-0">
                                <div class="card-body">
                                    <div class="p-3 bg-light rounded shadow d-inline-block">
                                        <img src={Clock} class="avatar avatar-small" alt="" />
                                    </div>
                                    <div class="mt-4">
                                        <h5><a href="javascript:void(0)" class="text-dark">Tes non Akademik</a></h5>
                                        <p class="text-muted mt-3 mb-0">Diambil dari verifikasi berkas surat keterangan sehat dan tidak buta warna, serta wawancara siswa.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">Jadwal</h4>
                                <p class="text-muted para-desc mb-0 mx-auto">Berikut jadwal PPDB<span class="text-primary font-weight-bold"> SMK Telkom Sidoarjo</span>  Tahun Ajaran 2021-2022.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="card pricing-rates bg-light py-5 border-0 rounded">
                                <div class="card-body">
                                    <div className={"text-center"}>
                                        <h2 class="title text-uppercase mb-4">Gelombang 1</h2>
                                    </div>

                                    <br />

                                    <ul class="list-unstyled mb-0 pl-0">
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Biaya Pendaftaran Rp. 150.000,00</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Pendaftaran 11 Januari-5 Maret 2021</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Seleksi 6 Maret 2021</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Pengumuman 8 Maret 2021</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Daftar Ulang 8-12 Maret  2021</li>
                                    </ul>

                                    <div className={"text-center"}>
                                        <a href="/register" class="btn btn-primary mt-4">Daftar Sekarang</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="card pricing-rates bg-light py-5 border-0 rounded">
                                <div class="card-body">
                                    <div className={"text-center"}>
                                        <h2 class="title text-uppercase mb-4">Gelombang 2</h2>
                                    </div>

                                    <ul class="list-unstyled mb-0 pl-0">
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Pendaftaran 11 Januari-5 Maret 2021</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Seleksi 6 Maret 2021</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Pengumuman 8 Maret 2021</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Daftar Ulang 8-12 Maret  2021</li>
                                    </ul>
                                    <div className={"text-center"}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br />

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">Biaya</h4>
                                <p class="text-muted para-desc mb-0 mx-auto">Berikut rincian biaya masuk PPDB<span class="text-primary font-weight-bold"> SMK Telkom Sidoarjo</span>  Tahun Ajaran 2021-2022.</p>
                            </div>
                        </div>

                        <div className={"col-md-3 col-12"}></div>
                        <div className={"col-md-6 col-12 mt-4 pt-2"}>
                            <table class="table mb-0 table-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Uraian</th>
                                        <th scope="col">Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Dana Sarana Prasarana</td>
                                        <td>Rp. 8.000.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>Pakaian Seragam</td>
                                        <td>Rp. 1.600.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>Biaya MPLS</td>
                                        <td>Rp. 150.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>Asuransi</td>
                                        <td>Rp. 150.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>Simp. Pokok Koperasi</td>
                                        <td>Rp. 50.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>Psikotes</td>
                                        <td>Rp. 100.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>SPP Bulan Juli 2021</td>
                                        <td>Rp. 450.000,00</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>Rp. 10.500.000,00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={"col-md-3 col-12"}></div>
                    </div>
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="title mb-4">Kerjasama Perusahaan</h4>
                                <p class="text-muted para-desc mx-auto mb-0">Berikut beberapa perusahaan yang bekerja sama dengan SMK Telkom Sidoarjo.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={TelkomIndonesia} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={Infomedia} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={TelkomMetra} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={Huawei} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="title mb-4">Kerjasama Perguruan Tinggi</h4>
                                <p class="text-muted para-desc mx-auto mb-0">Berikut beberapa perguruan tinggi yang bekerja sama dengan SMK Telkom Sidoarjo.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={TelkomUniversity} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={ITTelkomSurabaya} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={ITTelkomPurwokerto} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-4 pt-2">
                            <div class="card team text-center border-0">
                                <div class="position-relative">
                                    <img src={AkademiTelkomJakarta} class="img-fluid shadow" alt="" width={150} height={200} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section >



            {/* FAQ */}
            < section class="section bg-light" >
                <div class="container">
                    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-5 mt-sm-0 pt-sm-0 justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title">
                                <h4 class="title mb-4">Apakah Anda memiliki pertanyaan ? Silakan hubungi!</h4>
                                <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary font-weight-bold">SMK Telkom Sidoarjo</span> akan selalu membantu jika Anda memiliki kebingungan tentang kami.</p>
                                <a href="/contact" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Hubungi Kami</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <div class="position-relative">
                <div class="shape overflow-hidden text-footer">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
        </Fragment >
    )
}

export default Index