import Axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, TOKEN_KEY } from '../../../../Constants/constant'
import { AppContext } from '../../../../Context/AppContext'
import { ConvertToAngka, ConvertToRupiah } from '../../../../Helpers/rupiah'


function ReRegistrantion({ history }) {

    const appContext = useContext(AppContext)

    const {
        account,
        fetchAccount
    } = appContext

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [registrantInformation, setRegistrantInformation] = useState({
        NIK: '',
        religion: 'Islam',
        bornPlace: '',
        NISN: '',
        NIS: '',
        examNumber: '',
        height: '',
        weight: '',
        sibling: '',
        childNumber: '',
    })
    const [registrantLocation, setRegistrantLocation] = useState({
        RT: '',
        RW: '',
        village: '',
        district: '',
        city: '',
        zipCode: '',
        livingWith: 'Orang Tua',
        transportation: '',
        distance: '',
        time: '',
    })
    const [registrantFather, setRegistrantFather] = useState({
        name: '',
        NIK: '',
        bornDate: '',
        lastEducation: '',
        job: '',
        salary: 0,
        phone: '',
    })
    const [registrantMother, setRegistrantMother] = useState({
        name: '',
        NIK: '',
        bornDate: '',
        lastEducation: '',
        job: '',
        salary: 0,
        phone: '',
    })
    const [registrantGuardian, setRegistrantGuardian] = useState({
        name: '-',
        NIK: '-',
        bornDate: '2020-10-08',
        lastEducation: '-',
        job: '-',
        salary: 0,
        phone: '-',
    })

    useEffect(() => {
        window.feather.replace()
    }, [])

    const saveRegistrantInformation = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(registrantInformation)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/registrant-information", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan informasi")
                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    const saveRegistrantLocation = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(registrantLocation)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/registrant-location", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan informasi")
                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    const saveRegistrantFather = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(registrantFather)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/registrant-family/father", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan informasi")
                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    const saveRegistrantMother = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(registrantMother)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/registrant-family/mother", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan informasi")
                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    const saveRegistrantGuardian = () => {
        setIsSubmitting(true)

        const formData = new FormData();

        for (const [key, value] of Object.entries(registrantGuardian)) {
            formData.append(key, value)
        }

        Axios.post(BASE_URL + "/registrant-family/guardian", formData, {
            headers: {
                Role: 'Registrant',
                Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
            }
        })
            .then(response => {

                if (response.data.status) {
                    toast.success("Sukses menambahkan informasi")
                    fetchAccount()
                    setIsSubmitting(false)
                } else {
                    setIsSubmitting(false)

                    Object.keys(response.data.errors).map((value) => {
                        toast.error(response.data.errors[value][0])
                    })
                }

            })
            .catch(error => {
                Object.keys(error.response.data.errors).map((value) => {
                    toast.error(error.response.data.errors[value][0])
                })
                setIsSubmitting(false)
            })
    }

    return (
        <Fragment>
            <div>
                {
                    !account.registrant.registrantInformation ?
                        <div>
                            <h4>Informasi Dasar</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NIK</label>
                                        <input type="text" className="form-control" placeholder={"NIK"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, NIK: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Agama</label>
                                        <select class="form-control custom-select" onChange={(event) => { setRegistrantInformation({ ...registrantInformation, religion: event.target.value }) }}>
                                            <option value="Islam">Islam</option>
                                            <option value="Katolik">Katolik</option>
                                            <option value="Protestan">Protestan</option>
                                            <option value="Hindu">Hindu</option>
                                            <option value="Budha">Budha</option>
                                            <option value="Konghucu">Konghucu</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Tempat Lahir</label>
                                        <input type="text" className="form-control" placeholder={"Tempat Lahir"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, bornPlace: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NISN</label>
                                        <input type="text" className="form-control" placeholder={"NISN"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, NISN: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NIS</label>
                                        <input type="text" className="form-control" placeholder={"NIS"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, NIS: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nomor Ujian</label>
                                        <input type="text" className="form-control" placeholder={"Nomor Ujian"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, examNumber: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Tinggi Badan</label>
                                        <input type="text" className="form-control" placeholder={"Tinggi Badan"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, height: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Berat Badan</label>
                                        <input type="text" className="form-control" placeholder={"Berat Badan"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, weight: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Jumlah Saudara</label>
                                        <input type="text" className="form-control" placeholder={"Jumlah Saudara"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, sibling: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Anak Urutan Ke</label>
                                        <input type="text" className="form-control" placeholder={"Anak Urutan ke"} onChange={(event) => { setRegistrantInformation({ ...registrantInformation, childNumber: event.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={saveRegistrantInformation}>Simpan Data</button>
                            </div>
                        </div> : null
                }

                {
                    !account.registrant.registrantLocation ?
                        <div>
                            <br /><br />
                            <h4>Informasi Lokasi</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>RT</label>
                                        <input type="text" className="form-control" placeholder={"RT"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, RT: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>RW</label>
                                        <input type="text" className="form-control" placeholder={"RW"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, RW: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Desa/Kelurahan</label>
                                        <input type="text" className="form-control" placeholder={"Desa/Kelurahan"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, village: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Kecamatan</label>
                                        <input type="text" className="form-control" placeholder={"Kecamatan"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, district: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Kota</label>
                                        <input type="text" className="form-control" placeholder={"Kota"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, city: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Kode Pos</label>
                                        <input type="text" className="form-control" placeholder={"Kode Pos"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, zipCode: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Tinggal Bersama</label>
                                        <select class="form-control custom-select" onChange={(event) => { setRegistrantLocation({ ...registrantLocation, livingWith: event.target.value }) }}>
                                            <option value="Orang Tua">Orang Tua</option>
                                            <option value="Saudara">Saudara</option>
                                            <option value="Orang Lain">Orang Lain</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Transporatsi</label>
                                        <input type="text" className="form-control" placeholder={"Transportasi"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, transportation: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Jarak Tempuh Ke Sekolah (KM)</label>
                                        <input type="text" className="form-control" placeholder={"Jarak Tempuh Ke Sekolah"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, distance: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Waktu Ke Sekolah (Menit)</label>
                                        <input type="text" className="form-control" placeholder={"Waktu Ke Sekolah"} onChange={(event) => { setRegistrantLocation({ ...registrantLocation, time: event.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={saveRegistrantLocation}>Simpan Data</button>
                            </div>
                        </div> : null
                }

                {
                    !account.registrant.registrantFather ?
                        <div>
                            <br /><br />
                            <h4>Informasi Ayah</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nama</label>
                                        <input type="text" className="form-control" placeholder={"Nama"} onChange={(event) => { setRegistrantFather({ ...registrantFather, name: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NIK</label>
                                        <input type="text" className="form-control" placeholder={"NIK"} onChange={(event) => { setRegistrantFather({ ...registrantFather, NIK: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Tanggal Lahir</label>
                                        <input type="date" className="form-control" onChange={(event) => { setRegistrantFather({ ...registrantFather, bornDate: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pendidikan Terakhir</label>
                                        <select class="form-control custom-select" onChange={(event) => { setRegistrantFather({ ...registrantFather, lastEducation: event.target.value }) }}>
                                            <option value="S3">S3</option>
                                            <option value="S2">S2</option>
                                            <option value="S1">S1</option>
                                            <option value="SMA/SMK">SMA/SMK</option>
                                            <option value="SMP">SMP</option>
                                            <option value="SD">SD</option>
                                            <option value="Belum Sekolah">Belum Sekolah</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pekerjaan</label>
                                        <input type="text" className="form-control" placeholder={"Pekerjaan"} onChange={(event) => { setRegistrantFather({ ...registrantFather, job: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Gaji</label>
                                        <input type="text" className="form-control" value={ConvertToRupiah(registrantFather.salary)} placeholder={"Gaji"} onChange={(event) => { setRegistrantFather({ ...registrantFather, salary: ConvertToAngka(event.target.value) }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nomor Telepon</label>
                                        <input type="text" className="form-control" placeholder={"Nomor Telepon"} onChange={(event) => { setRegistrantFather({ ...registrantFather, phone: event.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={saveRegistrantFather}>Simpan Data</button>
                            </div>
                        </div> : null
                }

                {
                    !account.registrant.registrantMother ?
                        <div>
                            <br /><br />
                            <h4>Informasi Ibu</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nama</label>
                                        <input type="text" className="form-control" placeholder={"Nama"} onChange={(event) => { setRegistrantMother({ ...registrantMother, name: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NIK</label>
                                        <input type="text" className="form-control" placeholder={"NIK"} onChange={(event) => { setRegistrantMother({ ...registrantMother, NIK: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Tanggal Lahir</label>
                                        <input type="date" className="form-control" onChange={(event) => { setRegistrantMother({ ...registrantMother, bornDate: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pendidikan Terakhir</label>
                                        <select class="form-control custom-select" onChange={(event) => { setRegistrantMother({ ...registrantMother, lastEducation: event.target.value }) }}>
                                            <option value="S3">S3</option>
                                            <option value="S2">S2</option>
                                            <option value="S1">S1</option>
                                            <option value="SMA/SMK">SMA/SMK</option>
                                            <option value="SMP">SMP</option>
                                            <option value="SD">SD</option>
                                            <option value="Belum Sekolah">Belum Sekolah</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pekerjaan</label>
                                        <input type="text" className="form-control" placeholder={"Pekerjaan"} onChange={(event) => { setRegistrantMother({ ...registrantMother, job: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Gaji</label>
                                        <input type="text" className="form-control" value={ConvertToRupiah(registrantMother.salary)} placeholder={"Gaji"} onChange={(event) => { setRegistrantMother({ ...registrantMother, salary: ConvertToAngka(event.target.value) }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nomor Telepon</label>
                                        <input type="text" className="form-control" placeholder={"Nomor Telepon"} onChange={(event) => { setRegistrantMother({ ...registrantMother, phone: event.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={saveRegistrantMother}>Simpan Data</button>
                            </div>
                        </div> : null
                }

                {
                    !account.registrant.registrantGuardian ?
                        <div>
                            <br /><br />
                            <h4>Informasi Wali</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nama</label>
                                        <input type="text" className="form-control" placeholder={"Nama"} onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, name: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>NIK</label>
                                        <input type="text" className="form-control" placeholder={"NIK"} onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, NIK: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Tanggal Lahir</label>
                                        <input type="date" className="form-control" onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, bornDate: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pendidikan Terakhir</label>
                                        <select class="form-control custom-select" onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, lastEducation: event.target.value }) }}>
                                            <option value="S3">S3</option>
                                            <option value="S2">S2</option>
                                            <option value="S1">S1</option>
                                            <option value="SMA/SMK">SMA/SMK</option>
                                            <option value="SMP">SMP</option>
                                            <option value="SD">SD</option>
                                            <option value="Belum Sekolah">Belum Sekolah</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Pekerjaan</label>
                                        <input type="text" className="form-control" placeholder={"Pekerjaan"} onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, job: event.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Gaji</label>
                                        <input type="text" className="form-control" value={ConvertToRupiah(registrantGuardian.salary)} placeholder={"Gaji"} onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, salary: ConvertToAngka(event.target.value) }) }} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label>Nomor Telepon</label>
                                        <input type="text" className="form-control" placeholder={"Nomor Telepon"} onChange={(event) => { setRegistrantGuardian({ ...registrantGuardian, phone: event.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary btn-block" disabled={isSubmitting} onClick={saveRegistrantGuardian}>Simpan Data</button>
                            </div>
                        </div> : null
                }
            </div>
        </Fragment>
    )
}

export default ReRegistrantion