import React, { Fragment, useEffect, useState } from 'react';
import WithPageView from './Helpers/GoogelAnalytics/WithPageView';

/**
 * Layout
 */
import Layout from './Components/Layouts/Layout';
import AppLayout from './Components/Layouts/AppLayout';
import AdminLayout from './Components/Layouts/AdminLayout';

/**
 * Page
 */
import NotFound from './Components/Layouts/NotFound';
import AboutUs from './Components/Pages/AboutUs/AboutUs';
import Index from './Components/Pages/Index/Index';
import Contact from './Components/Pages/Contact/Contact';

/**
 * Auth
 */
import Register from './Components/Pages/Auth/Register';
import Login from './Components/Pages/Auth/Login';
import ForgetPassword from './Components/Pages/Auth/FogetPassword';

/**
 * App
 */
import Profile from './Components/Pages/Student/Profile/Index';

/**
 * Admin
 */
import ProfileAdmin from './Components/Pages/Admin/Profile/index';

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";


function App() {
    return (
        <Fragment>
            <BrowserRouter>
                <Switch>
                    <Route path="/register" component={WithPageView(Register)} exact />
                    <Route path="/login" component={WithPageView(Login)} exact />
                    <Route path="/forget-password" component={WithPageView(ForgetPassword)} exact />
                    <Route path="/404" component={(WithPageView(NotFound))} exact />

                    <Layout>
                        <Route path="/" component={WithPageView(Index)} exact />
                        <Route path="/about" component={WithPageView(AboutUs)} exact />
                        <Route path="/contact" component={WithPageView(Contact)} exact />

                        <Route path="/profile" component={WithPageView(Profile)} exact />

                        <Route path="/admin" component={WithPageView(ProfileAdmin)} exact />
                        
                    </Layout>

                    <Redirect to="404" />
                </Switch>

            </BrowserRouter>
        </Fragment>
    );
}

export default App;
