import React, { createContext, useState, useEffect } from 'react'

import Axios from 'axios'
import { ACCOUNT_KEY, BASE_URL, TOKEN_KEY } from '../Constants/constant'

export const AppContext = createContext()
export const AppContextConsumer = AppContext.Consumer

const AppContextProvider = ({ children }) => {
    const [account, setAccount] = useState({})
    const [active, setActive] = useState(0)
    const [finishSection, setFinishSection] = useState({1: false, 2: false, 3: false, 4: false, 5: false})

    const fetchAccount = () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            if (localStorage.getItem(ACCOUNT_KEY)) {
                setAccount(JSON.parse(localStorage.getItem(ACCOUNT_KEY)))
            }

            Axios.post(BASE_URL + "/account", null, {
                headers: {
                    Role: 'Registrant',
                    Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
                }
            }).then(response => {
                if (response.data.status) {
                    localStorage.setItem(ACCOUNT_KEY, JSON.stringify(response.data.data))

                    setAccount(JSON.parse(localStorage.getItem(ACCOUNT_KEY)))
                } else {
                    window.location.href = "/login"
                }
            }).catch(err => {
                window.location.href = "/login"
            })
        }
    }

    useEffect(() => {
        setStep()
    }, [account])

    useEffect(() => {
    }, [active])

    const setStep = () => {
        if (!(account.uncompletedSteps?.confirmed)) {
            setActive(0)
            return
        }

        if (!(account.uncompletedSteps?.form)) {
            setActive(2)
            return
        }

        if (!(account.uncompletedSteps?.registrantDocument) || !(account.uncompletedSteps?.reportDocument) || !(account.uncompletedSteps?.reportScore)) {
            setActive(3)
            return
        }

        if (!(account.uncompletedSteps?.documentConfirmed)) {
            setActive(4)
            return
        }

        if(!(account.uncompletedSteps?.passedTest)) {
            setActive(4)
            return
        }

        if (!(account.uncompletedSteps?.reregistration)) {
            setActive(4)
            return
        }

        setActive(0)
        return
    }

    return <AppContext.Provider value={{
        fetchAccount,

        account,
        setAccount,

        active,
        setActive,

        finishSection, 
        setFinishSection,
    }}>{children}</AppContext.Provider>
}

export default AppContextProvider